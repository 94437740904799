<template>
  <v-app dark>
    <v-main>
      <v-flex>
        <v-app-bar v-if="$store.state.loggedIn" class="elevation-0 grad" dark>
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-tooltip
              v-for="extraButton in extraToolbarButtons"
              :key="extraButton.tooltipText"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="extraButton.disabled"
                  @click="extraButton.callback"
                  v-on="on"
                >
                  <v-icon class="black--text">
                    {{ extraButton.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ extraButton.tooltipText }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  class="black--text"
                  v-bind="attrs"
                  @click="$emit('darkModeChanged')"
                  v-on="on"
                >
                  <v-icon>mdi-theme-light-dark</v-icon>
                </v-btn>
              </template>
              <span>Change to light/dark mode</span>
            </v-tooltip>
          </v-toolbar-items>
          <v-btn
            v-if="$store.state.loggedIn"
            class="elevation-0 grey darken-3"
            @click="logout()"
            >Logout</v-btn
          >
        </v-app-bar>
        <v-navigation-drawer
          v-if="$store.state.loggedIn"
          v-model="drawer"
          absolute
          bottom
          temporary
          :width="375"
        >
          <v-list-item link to="/">
            <v-list-item-content class="d-flex justify-center">
              <img src="/images/eCom_Solution.png" class="menu-logo" />
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list nav>
            <menu-item
              v-for="rootItem in $store.state.routes"
              :key="rootItem.name"
              :item="rootItem"
              :sub-group="false"
            >
            </menu-item>
          </v-list>
          <template #append>
            <span
              v-if="$store.state.mustConnectMicrosoftAccount"
              class="nav-alert__text"
              >You have to connect your Microsoft account. Please do so by
              clicking <a :href="loginazure"> here</a></span
            >
          </template>
        </v-navigation-drawer>
        <router-view v-on="$listeners" @PageInfoReceived="onPageInfoReceived" />
      </v-flex>
    </v-main>
    <v-snackbar
      v-model="showErrors"
      timeout="6000"
      bottom
      tile
      color="error"
      multi-line
    >
      <div class="snackbar-content">
        <v-icon>mdi-close-circle</v-icon>
        <span v-for="(error, index) in errors" :key="index">{{ error }}</span>
      </div>
      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showErrors = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-for="message in successMessages"
      :key="message.id"
      :value="message.isVisible"
      :style="{ 'margin-bottom': calculateSnackbarMargin(message) }"
      timeout="4000"
      bottom
      multi-line
      tile
      color="success"
      @input="removeSuccessMessage(message)"
    >
      <div class="snackbar-content">
        <v-icon>mdi-check-circle</v-icon>
        <span>{{ message.message }}</span>
      </div>
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="removeSuccessMessage(message)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { ToolbarItem } from "./models/ToolbarItem";

import EventBus from "./event-bus";
import { AccountApi } from "./openapi";

interface SnackbarMessage {
  id: string;
  isVisible: boolean;
  message: string;
}

const api = new AccountApi(undefined, "");
@Component({})
export default class App extends Vue {
  showErrors = false;
  errors: string[] = [];
  drawer = false;
  title = "";
  loginazure =
    "/account/loginazure?path=/account/ClearAzureLogin%3Fpath=" +
      (this.$route.query.next as string) || "/";
  extraToolbarButtons: ToolbarItem[] = [];
  successMessages: SnackbarMessage[] = [];
  clicked = false;

  darkMode() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  logout() {
    if (this.$store.state.loggedIn) {
      api
        .deleteJobsFromEmployee()
        .then(() => {
          api
            .logout()
            .then(() => {
              const isLoginPage =
                this.$router.currentRoute &&
                this.$router.currentRoute.name == "Login";
              this.$store.commit("setLoggedoutState");
              this.$store.commit("setIsBpoUser", false);
              if (!isLoginPage) {
                this.$router.push({
                  path: "/login",
                });
              }
            })
            .catch((error) => {
              let errors = [error.message];
              this.onErrorOccurred(errors);
            });
        })
        .catch((error) => {
          let errors = [error.message];
          this.onErrorOccurred(errors);
        });
    }
  }

  onErrorOccurred(errors: string[]) {
    this.errors = errors;
    this.showErrors = true;
  }
  onSuccessOccurred(successMessages: string[]) {
    for (const message of successMessages) {
      const newItem = {
        id: `${this.successMessages.length}-${Date.now()}`,
        isVisible: true,
        message: message,
      } as SnackbarMessage;
      this.successMessages.push(newItem);
    }
  }
  removeSuccessMessage(item: SnackbarMessage) {
    const index = this.successMessages.findIndex((c) => c.id === item.id);
    this.successMessages.splice(index, 1);
  }

  calculateSnackbarMargin(item: SnackbarMessage) {
    const index = this.successMessages.findIndex((c) => c.id === item.id);
    return index * 80 + "px";
  }

  created() {
    EventBus.$on("onError", this.onErrorOccurred);
    EventBus.$on("onSuccess", this.onSuccessOccurred);
  }

  destroyed() {
    EventBus.$off("onError", this.onErrorOccurred);
    EventBus.$off("onSuccess", this.onSuccessOccurred);
  }

  @Watch("$route")
  onRouteChanged(to: string, from: string) {
    this.title = "";
    this.extraToolbarButtons = [];
  }

  onPageInfoReceived(title: string, toolbarButtons: ToolbarItem[]) {
    this.title = title;
    this.extraToolbarButtons = toolbarButtons;
  }

  checkUserStillActive() {
    const isLoginPage =
      this.$router.currentRoute && this.$router.currentRoute.name == "Login";
    if (!this.$store.state.loggedIn) {
      if (!isLoginPage) {
        this.$router.push({
          path: "/login",
        });
      }
      return;
    }
    if (this.clicked) {
      this.clicked = false;
      api
        .updateUserActive()
        .then((result) => {
          if (!result.data.active) {
            this.logout();
          } else {
            this.$store.commit("setCheckUserStillActive", result.data);
            this.$store.commit("setIsBpoUser", result.data.isBpoUser);
            if (isLoginPage && this.$router.currentRoute.name != "Home") {
              this.$router.push({
                path: "/",
              });
            }
          }
        })
        .catch((error) => {
          this.onErrorOccurred([error.message]);
        });
    } else {
      api
        .checkUserStillActive()
        .then((result) => {
          if (!result.data.active) {
            this.logout();
          } else {
            this.$store.commit("setCheckUserStillActive", result.data);
            this.$store.commit("setIsBpoUser", result.data.isBpoUser);
            if (isLoginPage && this.$router.currentRoute.name != "Home") {
              this.$router.push({
                path: "/",
              });
            }
          }
        })
        .catch((error) => {
          this.onErrorOccurred([error.message]);
        });
    }
  }

  async clickedSet() {
    if (!this.clicked) {
      this.clicked = true;
    }
  }

  mounted() {
    this.checkUserStillActive();
    setInterval(() => {
      this.checkUserStillActive();
    }, 30000);
    document.addEventListener("click", this.clickedSet);
  }
}
</script>

<style scoped lang="scss">
.snackbar-content {
  display: flex;
  align-items: center;
  gap: 15px;
}
</style>
