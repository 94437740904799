import { Component, Vue } from "vue-property-decorator";
import { LoginViewModel } from "../api/viewmodels/LoginViewModel";
import { ValidationObserver } from "vee-validate";
import { Store } from "vuex";
import { RootState } from "@/store";

import { AccountApi, LoginResultViewModel } from "../openapi";
const accountApi = new AccountApi(undefined, "");

@Component({})
export default class Login extends Vue {
  showPassword = false;
  username = "";
  password = "";
  loggingIn = false;
  loginazure =
    "/account/loginazure?path=/account/ClearAzureLogin%3Fpath=" +
      (this.$route.query.next as string) || "/";
  loginResult: LoginResultViewModel | null = null;
  store = this.$store as Store<RootState>;
  login() {
    if (this.$store.state.loggedIn) {
      return;
    }

    const observer = this.$refs.observer as InstanceType<
      typeof ValidationObserver
    >;
    observer.validate().then((success: boolean) => {
      if (!success) {
        return;
      }
      const loginVm: LoginViewModel = {
        username: this.username,
        password: this.password,
      };
      accountApi
        .login(loginVm)
        .then((response) => {
          if (response.data.success) {
            accountApi
              .deleteJobsFromEmployee()
              .then((result) => {
                this.$router.push({
                  path: (this.$route.query.next as string) || "/",
                });
              })
              .catch((error) => {
                const errors = [error.message];
                this.$emit("errorOccured", errors);
              });
              this.$store.commit("setLoggedinState", response.data);
              this.$store.commit("setIsBpoUser", response.data.isBpoUser);
          } else {
            this.loginResult = response.data;
          }
        })
        .catch((error) => {
          const errors = [error.message];
          this.$emit("errorOccured", errors);
        });
    });
  }
}
