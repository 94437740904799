import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import RatecardValues from './RatecardValues.vue.html';

@Component({
    components: {
        RatecardValues,
    },
})
export default class SinglePositionsRatecard extends Vue {
    currentTab: number | null = 0;

    showDialogNewRatecard: boolean = false;

    showDialogDeleteRatecard: boolean = false;
    isLoading: boolean = false;
    selectedTemplate: number | null = null;
    priceIsManually = false;

    canEditRatecardName: boolean = false;

    subCategories = ['A1 service', 'A2 service', 'A3 service'];
    selectedSubCategories = [];

    deleteRatecard() {
        // Logic to be added at a later date (SOVC-669)
    }

    createRatecard() {
        // Logic to be added at a later date (SOVC-669)
    }

    openDialogNewRatecard() {
        this.showDialogNewRatecard = true;
    }

    editRatecardName() {
        this.canEditRatecardName = true;
    }

    openDialogDeleteRatecard() {
        this.showDialogDeleteRatecard = true;
    }

    saveRatecard() {
        // Logic to be added at a later date (SOVC-669)
    }
}
