<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-toolbar dark color="grey darken-3">
                        <v-btn
                            icon
                            :to="{
                                name: 'MaintenanceOperationalCustomsAusfuhrInfoList',
                            }"
                            exact
                        >
                            <v-icon>mdi-arrow-left</v-icon></v-btn
                        >
                        <v-toolbar-title
                            >{{ title }} - Warenorte Export</v-toolbar-title
                        >
                    </v-toolbar>
                    <v-card-text>
                        <ValidationObserver
                            class="form"
                            v-slot="{ invalid, dirty }"
                        >
                            <div class="form-fields">
                                <TextField
                                    v-model="formData.name"
                                    name="Name"
                                    label="Name*"
                                    rules="required|max:100"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="formData.address"
                                    name="Address"
                                    label="Address*"
                                    rules="required|max:300"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="formData.postalCode"
                                    name="Postal code"
                                    label="Postal code*"
                                    rules="required|max:50"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="formData.city"
                                    name="City"
                                    label="City*"
                                    rules="required|max:200"
                                    :disabled="loading"
                                ></TextField>
                                <ValidationProvider
                                    name="Country"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <v-select
                                        v-model="formData.countryId"
                                        :items="dataStore.originCountries"
                                        item-text="code"
                                        item-value="id"
                                        dense
                                        clearable
                                        label="Country*"
                                        outlined
                                        :disabled="loading"
                                        :loading="retrievingData"
                                        :error-messages="errors"
                                    ></v-select>
                                </ValidationProvider>
                                <TextField
                                    v-model="formData.zusatz"
                                    name="Zusatz"
                                    label="Zusatz*"
                                    rules="required|max:200"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="formData.ausfuhrzollstelle"
                                    name="Ausfuhrzollstelle"
                                    label="Ausfuhrzollstelle*"
                                    rules="required|max:50"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="
                                        formData.vorgeseheneAusgangszollstelle
                                    "
                                    name="Vorgesehene Ausgangszollstelle"
                                    label="Vorgesehene Ausgangszollstelle*"
                                    rules="required|max:50"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="
                                        formData.specialCircumstancesCharacteristics
                                    "
                                    name="Kennzeichen des besonderen Tatbestandes"
                                    label="Kennzeichen des besonderen Tatbestandes*"
                                    rules="required|max:100"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="
                                        formData.remarkSpecialCircumstancesCharacteristics
                                    "
                                    name="Vermerk / Erläuternder Text zum besonderen Tatbestand"
                                    label="Vermerk / Erläuternder Text zum besonderen Tatbestand*"
                                    rules="required|max:100"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="formData.aBDEmail"
                                    name="ABD email"
                                    label="ABD email*"
                                    rules="required|email|max:200"
                                    :disabled="loading"
                                ></TextField>
                                <TextField
                                    v-model="formData.aVMEmail"
                                    name="AVM email"
                                    label="AVM email*"
                                    rules="required|email|max:200"
                                    :disabled="loading"
                                ></TextField>
                            </div>
                            <div class="d-flex justify-end">
                                <v-btn
                                    color="success"
                                    class="mt-4"
                                    :disabled="
                                        (!saving && loading) ||
                                        !dirty ||
                                        invalid
                                    "
                                    :loading="saving"
                                    @click="saveAusfuhrInfo"
                                    ><v-icon left small
                                        >mdi-content-save</v-icon
                                    >
                                    {{ buttonText }}</v-btn
                                >
                            </div>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue';
import TextField from '@/components/editors/TextField.vue';
import { AusfuhrInfoDto, CustomsAusfuhrInfoApi } from '@/openapi';
import { emitErrorWithFallback, emitSuccess } from '@/event-bus';
import router from '@/router';
import { useDataStore } from '@/stores/data-store';

const dataStore = useDataStore();

interface CustomsAusfuhrInfoDetailProps {
    id?: number;
}
interface CustomsAusfuhrIntoDetailFormData {
    id?: number;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    countryId: number | null;
    zusatz: string;
    ausfuhrzollstelle: string;
    vorgeseheneAusgangszollstelle: string;
    specialCircumstancesCharacteristics: string;
    remarkSpecialCircumstancesCharacteristics: string;
    aBDEmail: string;
    aVMEmail: string;
}

const defaultFormValues: CustomsAusfuhrIntoDetailFormData = {
    name: '',
    address: '',
    postalCode: '',
    city: '',
    countryId: null,
    zusatz: '',
    ausfuhrzollstelle: '',
    vorgeseheneAusgangszollstelle: '',
    specialCircumstancesCharacteristics: '',
    remarkSpecialCircumstancesCharacteristics: '',
    aBDEmail: '',
    aVMEmail: '',
};

const api = new CustomsAusfuhrInfoApi(undefined, '');
const props = defineProps<CustomsAusfuhrInfoDetailProps>();

const formData = ref<CustomsAusfuhrIntoDetailFormData>({
    ...defaultFormValues,
});

const loading = ref(false);
const saving = ref(false);
const retrievingData = ref(false);

const setFormData = (responseData: AusfuhrInfoDto) => {
    formData.value.id = responseData.id!;
    formData.value.aBDEmail = responseData.abdEmail!;
    formData.value.aVMEmail = responseData.avmEmail!;
    formData.value.ausfuhrzollstelle = responseData.ausfuhrzollstelle!;
    formData.value.city = responseData.city!;
    formData.value.countryId = responseData.countryId!;
    formData.value.address = responseData.address!;
    formData.value.name = responseData.name!;
    formData.value.postalCode = responseData.postalCode!;
    formData.value.remarkSpecialCircumstancesCharacteristics =
        responseData.remarkSpecialCircumstancesCharacteristics!;
    formData.value.specialCircumstancesCharacteristics =
        responseData.specialCircumstancesCharacteristics!;
    formData.value.vorgeseheneAusgangszollstelle =
        responseData.vorgeseheneAusgangszollstelle!;
    formData.value.zusatz = responseData.zusatz!;
};

const getAusfuhrInfo = async () => {
    loading.value = true;
    try {
        const response = await api.getAusfuhrInfoDetail(props.id!);
        setFormData(response.data);
    } catch (e: unknown) {
        emitErrorWithFallback('Something went wrong while retrieving the data');
    }
    loading.value = false;
};

const saveAusfuhrInfo = async () => {
    if (props.id) {
        await editAusfuhrInfo();
    } else {
        await createAusfuhrInfo();
    }
};

const createAusfuhrInfo = async () => {
    saving.value = true;
    loading.value = true;
    try {
        const response = await api.postAusfuhrInfo({
            ausfuhrzollstelle: formData.value.ausfuhrzollstelle,
            city: formData.value.city,
            countryId: formData.value.countryId!,
            address: formData.value.address,
            name: formData.value.name,
            postalCode: formData.value.postalCode,
            remarkSpecialCircumstancesCharacteristics:
                formData.value.remarkSpecialCircumstancesCharacteristics,
            specialCircumstancesCharacteristics:
                formData.value.specialCircumstancesCharacteristics,
            vorgeseheneAusgangszollstelle:
                formData.value.vorgeseheneAusgangszollstelle,
            zusatz: formData.value.zusatz,
            abdEmail: formData.value.aBDEmail,
            avmEmail: formData.value.aVMEmail,
        });
        emitSuccess('Creation successful');
        setFormData(response.data);
        router.replace({
            name: router.currentRoute.name!,
            params: {
                id: response.data.id!.toString(),
            },
        });
    } catch (e: unknown) {
        emitErrorWithFallback('Something went wrong during creation');
    }

    saving.value = false;
    loading.value = false;
};

const editAusfuhrInfo = async () => {
    saving.value = true;
    loading.value = true;
    try {
        await api.putAusfuhrInfo(props.id!, {
            ausfuhrzollstelle: formData.value.ausfuhrzollstelle,
            city: formData.value.city,
            countryId: formData.value.countryId!,
            address: formData.value.address,
            name: formData.value.name,
            postalCode: formData.value.postalCode,
            remarkSpecialCircumstancesCharacteristics:
                formData.value.remarkSpecialCircumstancesCharacteristics,
            specialCircumstancesCharacteristics:
                formData.value.specialCircumstancesCharacteristics,
            vorgeseheneAusgangszollstelle:
                formData.value.vorgeseheneAusgangszollstelle,
            zusatz: formData.value.zusatz,
            abdEmail: formData.value.aBDEmail,
            avmEmail: formData.value.aVMEmail,
        });
        emitSuccess('Edit successful');
    } catch (e: unknown) {
        emitErrorWithFallback('Something went wrong while saving');
    }

    saving.value = false;
    loading.value = false;
};

const buttonText = computed(() => {
    return props.id ? 'Edit' : 'Create';
});

const title = computed(() => {
    return props.id ? 'Edit' : 'Create';
});

watch(
    () => props.id,
    (newValue: number | undefined, oldValue: number | undefined) => {
        if (newValue && newValue != formData.value.id) {
            getAusfuhrInfo();
        }
    },
    {
        immediate: true,
    }
);

onBeforeMount(async () => {
    retrievingData.value = true;
    await dataStore.fetchOriginCountries();
    retrievingData.value = false;
});
</script>
<style scoped lang="scss">
.form-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
}

.save-btn {
    align-self: flex-end;
}
</style>
