import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as CustomsExportController from '../api/CustomsExportController';
import CustomsExportShipmentDetailViewNew from './CustomsExportShipmentDetailViewNew.vue.html';
import { CustomsShipmentDetailViewModel } from '@/api/viewmodels/CustomsShipmentDetailViewModel';
import AddMrnDialog from './dialogs/AddMrnDialog.vue.html';
import ConfirmShipmentReasonDialog from './dialogs/ConfirmShipmentReasonDialog.vue.html';
import CustomsFlowStepsMixin from '@/mixins/CustomsFlowStepsMixin';
import ShipmentStatusFlow from './ShipmentStatusFlow.vue.html';
import ConfirmShipmentsDialog from './dialogs/ConfirmShipmentsDialog.vue.html';
import { getStatuses } from '@/helpers/statusHelper';
import {
    CustomsCheckApi,
    CustomsPaperworkHandlingApi,
    CustomsShipmentItemViewModel,
} from '../openapi';
import UploadPaperworkButton from './UploadPaperworkButton.vue.html';
import CountriesMixin from '@/mixins/CountriesMixin';
import { CustomsShipmentItemViewModel as TWCustomsShipmentItemViewModel } from '@/api/viewmodels/CustomsShipmentItemViewModel';
import ShipmentPaperwork from './ShipmentPaperwork.vue.html';
import ToolbarMixin from '@/mixins/ToolbarMixin';
import FilterParcels from './FilterParcels.vue.html';
import ConfirmShipmentButton from './buttons/ConfirmShipmentButton.vue.html';
import ReturnShipmentButton from './buttons/ReturnShipmentButton.vue.html';
import CustomsInspectionButton from './buttons/CustomsInspectionButton.vue.html';
import HoldShipmentButton from './buttons/HoldShipmentButton.vue.html';

const customsCheckApi = new CustomsCheckApi(undefined, '');

@Component({
    components: {
        CustomsExportShipmentDetailViewNew,
        AddMrnDialog,
        ShipmentStatusFlow,
        ConfirmShipmentReasonDialog,
        ConfirmShipmentsDialog,
        UploadPaperworkButton,
        ShipmentPaperwork,
        FilterParcels,
        ConfirmShipmentButton,
        ReturnShipmentButton,
        CustomsInspectionButton,
        HoldShipmentButton
    },
})
export default class CustomsExportCheck extends Mixins(
    CustomsFlowStepsMixin,
    CountriesMixin,
    ToolbarMixin
) {
    @Prop({ default: '' })
    color!: string;

    customsPaperworkHandlingApi: CustomsPaperworkHandlingApi = new CustomsPaperworkHandlingApi(
        undefined,
        ''
    );

    isLoading = false;
    options: any = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };
    filter: any = {
        step: null,
        shipmentStatusDescription: '',
        parcels: [],
        exportMrn: '',
        loadedConfirmed: 0,
        shipmentConfirmed: null,
        shipmentLoaded: null,
        weight: null,
        shipperCountry: null,
        consigneeCounty: null,
    };
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };
    totalItems: Number = 0;
    expanded = [];

    loadingSaveShipmentReason = false;
    loadingImportNewShipments = false;

    assignedCustomsHandlingItems = 0;

    checkShipmentStep = 2;

    statusFilter: number[] = [];
    shipmentStatusFilter = [
        'Hold',
        'Return',
        'Customs inspection',
        'Loading confirmed',
        'Waiting for customs release',
        'Customs EXIT released',
    ];

    loadedConfirmedFilter: any[] = [
        { text: 'Show all (mine)', value: 0 },
        { text: 'Show Confirmed but not loaded (mine)', value: 1 },
        { text: 'Show Loaded but not confirmed (all)', value: 2 },
    ];
    selectedLoadedConfirmed = '';

    booleanFilter = ['Yes', 'No'];

    items: CustomsShipmentItemViewModel[] = [];
    currentHandlingItem: CustomsShipmentItemViewModel | null = null;
    currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
    selectedHandlingItems: CustomsShipmentItemViewModel[] = [];

    currentAction = '';

    showDialogAddMrn = false;
    dialogAddMrnLoading = false;

    showConfirmShipmentsDialog = false;
    dialogConfirmShipmentsLoading = false;

    headers = [
        {
            text: 'Step',
            value: 'exportFlowStatus',
            align: 'center',
        },
        {
            text: 'Loaded',
            value: 'isLoaded',
            align: 'center',
        },
        {
            text: 'Confirmed',
            value: 'isConfirmed',
            align: 'center',
        },
        {
            text: 'Shipment status',
            value: 'shipmentStatusDescription',
        },
        {
            text: 'HAWB/Parcel ID',
            value: 'hawb',
            sortable: false,
            width: '15em',
        },
        {
            text: 'MRN ',
            value: 'exportMrn',
            width: '18em',
        },
        {
            text: 'Anzahl ',
            value: 'pieces',
            sortable: false,
            align: 'end',
        },
        {
            text: 'Gewicht ',
            value: 'grossWeight',
            sortable: false,
            align: 'end',
        },
        { text: 'EORI', value: 'eori', sortable: false },
        {
            text: 'Versender',
            value: 'shipper',
            sortable: false,
            width: '15em',
        },
        {
            text: 'Empfänger',
            value: 'consignee',
            sortable: false,
            width: '15em',
        },
        {
            text: 'Warenwert',
            value: 'value',
            sortable: false,
            align: 'end',
        },
        { text: 'Währung', value: 'valueCurrency', sortable: false },
        {
            text: 'Warenbeschreibung / tarifnummer',
            value: 'articles',
            sortable: false,
            width: '25em',
        },
        {
            text: 'Upload document(s)',
            value: 'documents',
            sortable: false,
        },
    ];

    get hasSelectedHandlingItems() {
        return !!this.selectedHandlingItems?.length;
    }

    get dataTableItems() {
        return this.items.map((c) => ({
            ...c,
            isSelectable: !c.isConfirmed && !this.isLoading,
        }));
    }

    created() {
        this.selectedLoadedConfirmed = this.loadedConfirmedFilter[0].text;
        this.importAssignedCustomsHandlingItems();
        this.statusFilter = getStatuses();

        this.addRefreshToolbarOption(this.reloadShipments);
    }

    async loadShipments(page: number) {
        this.isLoading = true;

        try {
            const response = await customsCheckApi.getCheckShipments({
                page,
                itemsPerPage: this.options.itemsPerPage,
                step: this.filter.step ?? undefined,
                shipmentStatusDescription: this.filter.shipmentStatusDescription ?? undefined,
                parcels: this.filter.parcels,
                exportMrn: this.filter.exportMrn ?? undefined,
                loadedConfirmed: this.filter.loadedConfirmed ?? undefined,
                shipmentLoaded: this.filter.shipmentLoaded ?? undefined,
                shipmentConfirmed: this.filter.shipmentConfirmed ?? undefined,
                weight: this.filter.weight ?? undefined,
                shipperCountry: this.filter.shipperCountry ?? undefined,
                consigneeCountry: this.filter.consigneeCountry ?? undefined
            });

            this.items = response.data.shipments ?? [];
            this.totalItems = response.data.totalAmount ?? 0;
        } catch {
            this.displayError(
                'Something went wrong while retrieving the shipments'
            );
        }
        this.isLoading = false;
    }

    async reloadShipments() {
        this.options.page == 1
            ? await this.loadShipments(1)
            : (this.options.page = 1);
    }

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.loadShipments(this.options.page);
        }, 250);
    }

    @Watch('expanded')
    onExpandedChanged(
        newVal: CustomsShipmentItemViewModel[],
        oldVal: CustomsShipmentItemViewModel[]
    ) {
        this.currentShipmentDetail = null;

        if (newVal.length === 0) {
            this.currentHandlingItem = null;
        } else {
            this.currentHandlingItem = newVal[0];

            this.getShipmentDetail();
        }
    }

    openDialogShipmentAddMrn(item: CustomsShipmentItemViewModel) {
        this.stopLoadingButtons();
        this.currentHandlingItem = item;
        this.showDialogAddMrn = true;
    }

    onFilterSelected() {
        this.reloadShipments();
    }

    stopLoadingButtons() {
        this.loadingSaveShipmentReason = false;
        this.loadingImportNewShipments = false;
    }

    closeAllDialogs() {
        this.showDialogAddMrn = false;
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    openDialogConfirmShipment() {
        this.stopLoadingButtons();
        this.showConfirmShipmentsDialog = true;
    }

    async importAssignedCustomsHandlingItems(
        getOnlyNumberOfAssigned: boolean = true
    ) {
        this.loadingImportNewShipments = true;

        try {
            var response = await customsCheckApi.importAssignedCustomsHandlingItems(
                getOnlyNumberOfAssigned
            );
            this.assignedCustomsHandlingItems = response.data;
            if (response.data > 0) {
                this.reloadShipments();
            }
        } catch {
            this.displayError(
                'Something went wrong while importing the assigned shipments.'
            );
        }

        this.loadingImportNewShipments = false;
    }

    async onMrnEntered({ mrn }: { mrn: string }) {
        this.dialogAddMrnLoading = true;
        try {
            await CustomsExportController.setMrn({
                mrn: mrn,
                shipmentId: this.currentHandlingItem!.id!,
            });

            this.currentHandlingItem!.exportMrn = mrn;
        } catch {
            this.displayError(
                'Something went wrong while setting the MRN of the shipment'
            );
        }

        this.dialogAddMrnLoading = false;
        this.showDialogAddMrn = false;
    }

    async getShipmentDetail() {
        this.isLoading = true;
        return CustomsExportController.getShipmentDetail(
            this.currentHandlingItem!.id!
        )
            .then((response) => {
                this.currentShipmentDetail = response.data;
            })
            .catch((error) =>
                this.displayError(
                    'Something went wrong while retrieving the detail data.'
                )
            )
            .finally(() => (this.isLoading = false));
    }

    async onShipmentConfirmed({ success }: any) {
        if(success) {
            this.expanded = [];
            this.loadShipments(this.options.page);
        }
    }

    async onShipmentsConfirmed() {
        this.dialogConfirmShipmentsLoading = true;
        await this.confirmShipments(
            this.selectedHandlingItems.map((item) => item.id!)
        );
        this.dialogConfirmShipmentsLoading = false;
        this.showConfirmShipmentsDialog = false;
        this.selectedHandlingItems = [];
    }

    async confirmShipments(shipmentIds: number[]) {
        try {
            await customsCheckApi.confirmShipments(shipmentIds);
            this.expanded = [];
            this.loadShipments(this.options.page);
        } catch {
            this.displayError(
                'Something went wrong while trying to confirm the shipment.'
            );
        }
    }

    async onShipmentDetailsUpdated() {
        this.isLoading = true;
        this.currentShipmentDetail = null;
        try {
            const response = await CustomsExportController.getShipmentDetail(
                this.currentHandlingItem!.id!
            );
            this.currentShipmentDetail = response.data;
        } catch {
            this.displayError(
                'Something went wrong while re-retrieving the shipment details'
            );
        }

        this.isLoading = false;
    }

    deselectItem(shipmentId: number) {
        var selectedItemIndex = this.selectedHandlingItems.findIndex(
            (item) => item.id === shipmentId
        );
        if (selectedItemIndex > -1) {
            this.selectedHandlingItems.splice(selectedItemIndex, 1);
            return;
        }
    }

    onShipmentStatusChanged({ success }: any) {
        if(success) {
            this.selectedHandlingItems = [];
            this.loadShipments(this.options.page);
        }
    }
}
