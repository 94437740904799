var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-form',[_c('v-card',{attrs:{"color":"grey darken-3"}},[_c('v-card-title',[_vm._v("Consignee address")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"Name","error-messages":errors,"outlined":""},model:{value:(_setup.consolidationStore.consignee.name),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "name", $$v)},expression:"consolidationStore.consignee.name"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Street","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"Street","error-messages":errors,"outlined":""},model:{value:(
                                    _setup.consolidationStore.consignee.street
                                ),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "street", $$v)},expression:"\n                                    consolidationStore.consignee.street\n                                "}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Zipcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"Zipcode","error-messages":errors,"outlined":""},model:{value:(
                                    _setup.consolidationStore.consignee.zipCode
                                ),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "zipCode", $$v)},expression:"\n                                    consolidationStore.consignee.zipCode\n                                "}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"City","error-messages":errors,"outlined":""},model:{value:(_setup.consolidationStore.consignee.city),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "city", $$v)},expression:"consolidationStore.consignee.city"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_setup.dataStore.originCountries,"error-messages":errors,"item-key":"code","item-text":"code","label":"Country","clearable":"","dense":"","outlined":"","single-line":"","hide-details":""},model:{value:(
                                    _setup.consolidationStore.consignee.country
                                ),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "country", $$v)},expression:"\n                                    consolidationStore.consignee.country\n                                "}}),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"EORI-number","outlined":""},model:{value:(
                                _setup.consolidationStore.consignee.eoriNumber
                            ),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "eoriNumber", $$v)},expression:"\n                                consolidationStore.consignee.eoriNumber\n                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"VAT number","outlined":""},model:{value:(_setup.consolidationStore.consignee.vatNumber),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "vatNumber", $$v)},expression:"consolidationStore.consignee.vatNumber"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Contact name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"Contact name","error-messages":errors,"outlined":""},model:{value:(
                                    _setup.consolidationStore.consignee.contactName
                                ),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee, "contactName", $$v)},expression:"\n                                    consolidationStore.consignee.contactName\n                                "}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Contact phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":"Contact phone","error-messages":errors,"outlined":""},model:{value:(
                                    _setup.consolidationStore.consignee
                                        .contactPhone
                                ),callback:function ($$v) {_vm.$set(_setup.consolidationStore.consignee
                                        , "contactPhone", $$v)},expression:"\n                                    consolidationStore.consignee\n                                        .contactPhone\n                                "}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Reason for export","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-radio-group',{attrs:{"column":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('strong',[_vm._v("Reason for export")])])]},proxy:true}],null,true),model:{value:(_setup.consolidationStore.reasonForExport),callback:function ($$v) {_vm.$set(_setup.consolidationStore, "reasonForExport", $$v)},expression:"consolidationStore.reasonForExport"}},[_c('v-radio',{attrs:{"label":"Sale","value":"01"}}),_c('v-radio',{attrs:{"label":"Return","value":"02"}}),_c('v-radio',{attrs:{"label":"Gift","value":"03"}})],1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":function($event){return _vm.$emit('next-step')}}},[_vm._v(" Continue ")])],1)],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }