import { render, staticRenderFns } from "./CustomsExportReadyForTransit.vue.html?vue&type=template&id=ab92c6ec"
import script from "./CustomsExportReadyForTransit.ts?vue&type=script&lang=ts&external"
export * from "./CustomsExportReadyForTransit.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports