import { CustomsShipmentDetailViewModel } from "@/api/viewmodels/CustomsShipmentDetailViewModel";
import EditableTextField from "./EditableTextField.vue";
import EditableDropdown from "./EditableDropdown.vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ShipmentDetailUpdateViewModel } from "../api/viewmodels/ShipmentDetailUpdateViewModel";
import * as CustomsExportController from "../api/CustomsExportController";
import * as CurrencyController from "../api/CurrencyController";
import { KeyValueItem } from "@/api/viewmodels/KeyValueItem";
import axios from "axios";
import {
  CountryOfOriginViewModel,
  DataApi,
  IncotermViewModel,
} from "@/openapi";
import ConfirmDialog from "./dialogs/ConfirmDialog.vue.html";
import CustomsParcels from "./CustomsParcels.vue.html";
import CustomsExportMrnDialog from "./dialogs/CustomsExportMrnDialog.vue";
import { KeyValueItem as OpenApiKeyValueItem } from "@/openapi";
import { ShipmentUpdateArticleViewModel } from "@/api/viewmodels/ShipmentUpdateArticleViewModel";
import { ArticleViewModel } from "@/api/viewmodels/ArticleViewModel";

const dataApi = new DataApi(undefined, "");

@Component({
  components: {
    EditableTextField,
    EditableDropdown,
    ConfirmDialog,
    CustomsParcels,
    CustomsExportMrnDialog,
  },
})
export default class CustomsExportShipmentDetailView extends Vue {
  @Prop({})
  currentHandlingItem!: CustomsShipmentDetailViewModel;

  @Prop({ default: true })
  readonly!: boolean;

  @Prop({ default: false })
  adminView!: boolean;

  initialLoading = true;
  isFormValid = true;
  copyCurrentHandlingItem: CustomsShipmentDetailViewModel | null = null;
  isLoading = false;

  currencies: KeyValueItem[] = [];
  selectedCurrency: KeyValueItem | null = null;

  routes: OpenApiKeyValueItem[] = [];
  selectedRoute: OpenApiKeyValueItem | null = null;

  incoTerms: KeyValueItem[] = [];
  originCountries: KeyValueItem[] = [];
  showConfirmDeleteDialog = false;
  confirmDeleteDialogLoading = false;
  currentArticle: ArticleViewModel | null = null;

  showParcelsDialog = false;
  showCustomsExportMrnDialog = false;

  rules = {
    rcTarif: (value: string) => {
      const pattern = /^\d{8}$/;
      return pattern.test(value) || "This field should contain 8 digits.";
    },
    maxWeight: (value: number) => {
      return value <= 50000 || "Weight can't be higher than 50000";
    },
    goodsItemNumber: (value: number | string | undefined) => {
      return (
        value === undefined ||
        value === "" ||
        (parseInt(value.toString()) >= 1 &&
          parseInt(value.toString()) <= 999) ||
        "The goods item number must be between 0 and 1000"
      );
    },
  };
  async created() {
    this.copyCurrentHandlingItem = JSON.parse(
      JSON.stringify(this.currentHandlingItem),
    );
    this.initialLoading = false;

    Promise.all([
      this.getCurrencies(),
      this.getRoutes(),
      this.getIncoTerms(),
      this.getOriginCountries(),
    ]);
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  async submitChanges() {
    const updateModel: ShipmentDetailUpdateViewModel = {
      shipmentId: this.currentHandlingItem.id,
      consigneeAddress: this.currentHandlingItem.consignee.address,
      consigneeCity: this.currentHandlingItem.consignee.city,
      consigneeCountry: this.currentHandlingItem.consignee.country,
      consigneeName: this.currentHandlingItem.consignee.name,
      consigneeZipcode: this.currentHandlingItem.consignee.zipcode,
      consigneeVat: this.currentHandlingItem.consigneeVat,
      eori: this.currentHandlingItem.eori,
      shipperAddress: this.currentHandlingItem.shipper.address,
      shipperCity: this.currentHandlingItem.shipper.city,
      shipperCountry: this.currentHandlingItem.shipper.country,
      shipperName: this.currentHandlingItem.shipper.name,
      shipperZipcode: this.currentHandlingItem.shipper.zipcode,
      shipperVat: this.currentHandlingItem.shipperVat,
      weight: this.currentHandlingItem.grossWeight,
      valueCurrency: this.currentHandlingItem!.valueCurrency,
      value: this.currentHandlingItem.value,
      articles:
        this.currentHandlingItem.articles?.map((c) => {
          return {
            id: c.id,
            description: c.description,
            netWeight:
              c.eigenmasse !== undefined && !isNaN(c.eigenmasse)
                ? c.eigenmasse
                : undefined,
            grossWeight:
              c.rohmasse !== undefined && !isNaN(c.rohmasse)
                ? c.rohmasse
                : undefined,
            value:
              c.rechnungspreis !== undefined && !isNaN(c.rechnungspreis)
                ? c.rechnungspreis
                : undefined,
            currency: c.rechnungswahrung,
            rcTarif: c.rcTarif,
            countryOfOrigin: c.countryOfOrigin,
            goodsItemNumber: this.parseGoodsItemNumberToNullableNumber(c),
          } as ShipmentUpdateArticleViewModel;
        }) ?? [],
      routeId: this.selectedRoute?.key,
      invoiceNumber: this.currentHandlingItem.invoiceNumber,
      reasonForExport: this.currentHandlingItem.reasonForExport,
      incoTerm: this.currentHandlingItem.incoTerm,
      countryOfOrigin: this.currentHandlingItem.countryOfOrigin,
      remarks: this.currentHandlingItem.remarks,
    };

    try {
      this.isLoading = true;
      await CustomsExportController.updateShipmentDetail(updateModel);
      this.$emit("updated-details");
    } catch {
      this.displayError(
        "Something went wrong while updating the values of the shipment",
      );
    }

    this.isLoading = false;
  }

  parseGoodsItemNumberToNullableNumber(articleViewModel: ArticleViewModel) {
    if (
      articleViewModel.goodsItemNumber === undefined ||
      articleViewModel.goodsItemNumber === null
    ) {
      return undefined;
    }

    if (Number.isNaN(articleViewModel.goodsItemNumber)) {
      return undefined;
    }

    return parseInt(articleViewModel.goodsItemNumber.toString());
  }

  getCurrency(currency: string) {
    return this.currencies?.find((c) => c.value === currency) ?? null;
  }

  getIncoterm(incotermId: number | null | undefined) {
    return this.incoTerms.find((c) => c.key === incotermId) ?? null;
  }

  getCountryOfOrigin(originCountryCode: string | null | undefined) {
    return (
      this.originCountries.find((c) => c.value === originCountryCode) ?? null
    );
  }

  async getCurrencies() {
    try {
      const response = await CurrencyController.getCurrencies();
      this.currencies = response.data;
      this.selectedCurrency =
        this.currencies.find(
          (c) => c.value === this.currentHandlingItem.valueCurrency,
        ) ?? null;
    } catch {
      this.displayError(
        "Something went wrong while retrieving the currencies necessary when changes the currency of the shipment",
      );
    }
  }

  async getRoutes() {
    try {
      const response = await dataApi.getRoutes();
      this.routes = response.data;
      this.selectedRoute =
        this.routes.find(
          (c) => c.key === this.currentHandlingItem.customsInformation?.routeId,
        ) ?? null;
    } catch {
      this.displayError("Something went wrong while retrieving the routes");
    }
  }

  async getIncoTerms() {
    try {
      const response = await axios.get("/Data/GetIncoTerms");
      this.incoTerms = response.data.map((c: IncotermViewModel) => ({
        key: c.id,
        value: c.name,
      }));
    } catch {
      this.displayError("Something went wrong while retrieving the incoterms");
    }
  }

  async getOriginCountries() {
    try {
      const response = await axios.get("/Data/GetOriginCountries");
      this.originCountries = response.data.map(
        (c: CountryOfOriginViewModel) => ({ key: c.id, value: c.code }),
      );
    } catch {
      this.displayError(
        "Something went wrong while retrieving the origin countries",
      );
    }
  }

  addEmptyArticle() {
    if (!this.currentHandlingItem.articles) {
      this.currentHandlingItem.articles = [];
    }

    const lowestId = Math.min(
      ...this.currentHandlingItem.articles.map((article) => article.id),
    );
    this.currentHandlingItem.articles.push({
      id: lowestId <= 0 ? lowestId - 1 : 0,
      customsHandlingId: this.currentHandlingItem.id,
      description: "",
      wtnNumber: "",
      eigenmasse: 0,
      rohmasse: 0,
      rechnungspreis: 0,
      rechnungswahrung: "",
      groupArticle: false,
      canBeGrouped: false,
      artikel: "",
      additionalCode: "",
      artikelPreis: undefined,
      frachtkosten: undefined,
      frachtkostenWahrung: "",
      zollwert: undefined,
      statWert: undefined,
      statMenge: undefined,
      massEinheit: "",
      zollMenge: undefined,
      countryOfOrigin: "",
      goodsItemNumber: 0,
    } as ArticleViewModel);
  }

  async deleteArticle(item: ArticleViewModel) {
    if (item.id! <= 0) {
      this.deleteFromArticles(item.id!);
      return;
    }

    try {
      await axios.post("/api/CustomsExport/DeleteArticle", {
        articleId: item.id,
      });
      this.deleteFromArticles(item.id!);
    } catch {
      this.displayError("Something went wrong while deleting the article");
    }
  }

  deleteFromArticles(articleId: number) {
    this.currentHandlingItem.articles.splice(
      this.currentHandlingItem.articles.findIndex(
        (art) => art.id === articleId,
      ),
      1,
    );
  }

  openEditExportMrnsDialog() {
    if (this.adminView) {
      this.showCustomsExportMrnDialog = true;
    }
  }

  closeEditExportMrnsDialog() {
    this.showCustomsExportMrnDialog = false;
  }

  exportMrnsSaved(list: string[]) {
    this.showCustomsExportMrnDialog = false;
    this.currentHandlingItem.customsInformation.exportMrns = list;
  }

  async confirmDelete() {
    this.confirmDeleteDialogLoading = true;
    await this.deleteArticle(this.currentArticle!);
    this.confirmDeleteDialogLoading = false;
    this.showConfirmDeleteDialog = false;
    this.currentArticle = null;
  }

  get hasChangedValues(): boolean {
    return (
      (!this.initialLoading &&
        JSON.stringify(this.currentHandlingItem) !==
          JSON.stringify(this.copyCurrentHandlingItem)) ||
      this.differentCurrency ||
      this.differentRoute
    );
  }

  get differentCurrency() {
    return (
      this.selectedCurrency != null &&
      this.copyCurrentHandlingItem != null &&
      this.copyCurrentHandlingItem.valueCurrency != this.selectedCurrency.value
    );
  }

  get differentRoute() {
    return (
      this.selectedRoute != null &&
      this.copyCurrentHandlingItem != null &&
      this.copyCurrentHandlingItem.customsInformation.routeId !=
        this.selectedRoute.key
    );
  }

  get exportMrns() {
    return (
      this.currentHandlingItem.customsInformation.exportMrns?.join(", ") ?? ""
    );
  }

  get invoiceNumbers() {
    return this.currentHandlingItem.invoiceNumbers.join(", ") ?? "";
  }

  get articleTotalNetWeight() {
    return this.currentHandlingItem.articles.reduce((prev, current) => {
      const value =
        current.eigenmasse !== undefined && !isNaN(current.eigenmasse)
          ? current.eigenmasse
          : 0;
      return prev + value;
    }, 0);
  }

  get articleTotalGrossWeight() {
    return this.currentHandlingItem.articles.reduce((prev, current) => {
      const value =
        current.rohmasse !== undefined && !isNaN(current.rohmasse)
          ? current.rohmasse
          : 0;
      return prev + value;
    }, 0);
  }

  get articleTotalValue() {
    return this.currentHandlingItem.articles.reduce((prev, current) => {
      const value =
        current.rechnungspreis !== undefined && !isNaN(current.rechnungspreis)
          ? current.rechnungspreis
          : 0;
      return prev + value;
    }, 0);
  }

  get groupedDocuments() {
    const map = new Map();

    for (const document of this.currentHandlingItem.systemInformation
      .documents) {
      const existingValue = map.get(document.employee);
      if (!existingValue) {
        map.set(document.employee, [document]);
        continue;
      }

      existingValue.push(document);
    }

    return Array.from(map);
  }
}
