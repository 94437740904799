<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog v-model="internalModelOpen">
      <template #header
        ><span class="title-holder">{{
          internalModelValue.name
        }}</span></template
      >
      <template #content>
        <v-form slim>
          <div class="form-fields">
            <ValidationProvider
              v-slot="{ errors }"
              name="Currencie name"
              rules="required"
            >
              <v-text-field
                v-model="internalModelValue.name"
                label="Currencie name"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
        </v-form>
      </template>
      <template #footer
        ><v-spacer></v-spacer>
        <v-btn :disabled="loading" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn
          :disabled="invalid"
          color="success"
          :loading="loading"
          @click="$emit('save', internalModelValue)"
          >Save</v-btn
        ></template
      >
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { CurrencyViewModel } from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import { ref, watch } from "vue";
interface PaymentTermProps {
  modelOpen: boolean;
  modelValue: CurrencyViewModel;
  loading: boolean;
}
const props = defineProps<PaymentTermProps>();
const emit = defineEmits([
  "cancel",
  "save",
  "update:model-open",
  "update:model-value",
]);

const internalModelOpen = ref(props.modelOpen);
const internalModelValue = ref(props.modelValue);

watch(
  () => props.modelOpen,
  (newVal) => {
    if (internalModelOpen.value != newVal) {
      internalModelOpen.value = newVal;
    }
  },
);

watch(
  () => props.modelValue,
  (newVal) => {
    if (internalModelValue.value != newVal) {
      internalModelValue.value = newVal;
    }
  },
);

watch(internalModelOpen, (newVal) => {
  emit("update:model-open", newVal);
});
</script>
<style lang="scss" scoped>
.title-holder {
  display: inline-block;
  min-height: 32px;
}
</style>
