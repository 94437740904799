

import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)

import { CustomsOfficeCustomerViewModel } from './viewmodels/CustomsOfficeCustomerViewModel'
import { PagedResultCustomsOfficeCustomerViewModel } from './viewmodels/PagedResultCustomsOfficeCustomerViewModel'


        
    // GetCustomsOfficeCustomers
    export function getCustomsOfficeCustomers (amount: number, page: number, orderByField: string, orderBy: string) : AxiosPromise<PagedResultCustomsOfficeCustomerViewModel> {
        return axios({
            url: `/api/CustomsOfficeCustomer/getCustomsOfficeCustomers/?amount=${amount}&page=${page}&orderByField=${encodeURIComponent(orderByField)}&orderBy=${encodeURIComponent(orderBy)}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // SaveCustomsOfficeCustomer
    export function saveCustomsOfficeCustomer (model: CustomsOfficeCustomerViewModel) : AxiosPromise<CustomsOfficeCustomerViewModel> {
        return axios({
            url: `/api/CustomsOfficeCustomer/saveCustomsOfficeCustomer/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // DeleteCustomsOfficeCustomer
    export function deleteCustomsOfficeCustomer (id: number) : AxiosPromise<void> {
        return axios({
            url: `/api/CustomsOfficeCustomer/deleteCustomsOfficeCustomer/${id}`, 
            method: "post",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };

