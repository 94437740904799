<template>
  <Fragment>
    <ValidationObserver slim ref="observer" v-slot="{ invalid }">
      <DefaultDialog
        :value="value"
        :max-width="maxWidth"
        @input="$emit('input', $event)"
      >
        <template v-slot:header> Search MRN </template>
        <template v-slot:content>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    :options="options"
                    item-key="mrn"
                    dense
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                  >
                    <template v-slot:top>
                      <div class="px-4 py-4">
                        <v-text-field
                          v-model="search"
                          placeholder="Search"
                          append-icon="mdi-magnify"
                          :disabled="loading"
                        ></v-text-field>
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-start">{{ item.mrn }}</td>
                        <td class="text-start">{{ item.customer }}</td>
                        <td class="text-start">
                          {{ formatDateTimeWithTime(item.dateAdded) }}
                        </td>
                        <td v-if="showStatusInfo"></td>
                        <td class="text-start">
                          <v-icon
                            :color="item.isMrnConfirmed ? 'success' : 'error'"
                          >
                            {{
                              item.isMrnConfirmed ? "mdi-check" : "mdi-close"
                            }}</v-icon
                          >
                        </td>
                        <td class="text-start">
                          <v-btn
                            color="primary"
                            class="ma-2"
                            small
                            @click="selectMRN(item)"
                            >Select <v-icon right>mdi-eye</v-icon></v-btn
                          >
                        </td>
                      </tr>
                      <tr v-if="showStatusInfo">
                        <td></td>
                        <td class="text-start extra-info-main">
                          <span>
                            Customs clearance sent:
                            {{ item.customsClearanceSent }}<br />&nbsp;</span
                          >
                        </td>
                        <td class="text-start extra-info">
                          <span>
                            DDP: {{ item.customsClearanceSentDDP }} <br />DAP:
                            {{ item.customsClearanceSentDAP }}</span
                          >
                        </td>
                        <td class="text-start extra-info-main">
                          <span>
                            Status received: {{ item.receivedStatus
                            }}<br />Final cleared: {{ item.finalCleared }}</span
                          >
                        </td>
                        <td class="text-start extra-info">
                          <span>
                            Pre-Cleared: {{ item.preCleared }} <br />
                            Inspections: {{ item.customsInspections }}</span
                          >
                        </td>
                        <td></td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loading"
            @click="$emit('input', false), $emit('close')"
          >
            Cancel
          </v-btn>
        </template>
      </DefaultDialog>
    </ValidationObserver>
  </Fragment>
</template>

<script setup lang="ts">
import moment from "moment";
import { emitError } from "@/event-bus";
import { MrnDto, MrnApi } from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import { DataOptions, DataTableHeader } from "vuetify";
import { computed, onMounted, ref } from "vue";

interface ShowMrnDialogProps {
  value: boolean;
  preCheckConfirmed?: boolean;
  mrnConfirmed?: boolean;
  showConfirmedColumn?: boolean;
  onlyShowUncompleted?: boolean;
  showStatusInfo?: boolean;
}

const mrnApi = new MrnApi(undefined, "");

const props = withDefaults(defineProps<ShowMrnDialogProps>(), {
  value: false,
  mrnConfirmed: undefined,
  onlyShowUncompleted: false,
  preCheckConfirmed: undefined,
  showConfirmedColumn: false,
  showStatusInfo: false,
});
const emits = defineEmits(["errorOccured", "getMrn"]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 10,
  sortBy: ["dateAdded"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const items = ref<MrnDto[]>([]);

const loading = ref(true);
const search = ref("");

const getMrnData = async () => {
  try {
    const response = await mrnApi.getMrnData(
      props.mrnConfirmed,
      props.preCheckConfirmed,
      props.onlyShowUncompleted,
      props.showStatusInfo,
    );
    items.value = response.data ?? [];
  } catch {
    emitError("Something went wrong while retrieving the data.");
  }
  loading.value = false;
};

const selectMRN = (mrn: MrnDto) => {
  emits("getMrn", mrn);
};

const formatDateTimeWithTime = (dateTime: Date) => {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }
  return moment(dateTime).format("DD-MM-YYYY HH:mm:ss");
};

const maxWidth = computed(() => {
  return props.showStatusInfo ? "1200px" : "900px";
});

const headers = computed(() => {
  const headers: DataTableHeader[] = [
    { text: "MRN number", value: "mrn", sortable: true },
    { text: "General customer", value: "customer", sortable: true },
    { text: "MRN date added", value: "dateAdded", sortable: true },
  ];
  if (props.showStatusInfo) {
    headers.push({
      text: "",
      value: "extraInfo",
      sortable: false,
    });
  }

  if (props.showConfirmedColumn) {
    headers.push({
      text: "Confirmed",
      value: "isMrnConfirmed",
      sortable: true,
    });
  }
  headers.push({
    text: "",
    value: "actions",
    sortable: false,
    width: "6em",
  });

  return headers;
});

onMounted(async () => {
  await getMrnData();
});
</script>

<style scoped lang="scss">
.extra-info-main {
  span {
    display: block;
    white-space: nowrap;
    font-size: 0.8em;
  }
}
.extra-info {
  span {
    white-space: nowrap;
    font-size: 0.8em;
  }
}
</style>
