import Vue from "vue";

import VueRouter from "vue-router";
import store from "@/store";
import { finalRoutes } from "@/stores/route-store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: finalRoutes,
});
const defaultPages = ["Login", "Home"];
router.beforeEach((to, from, next) => {
  const matched = to.matched;
  const requiresLogin = matched.some((route) => route.meta.loggedIn != false);
  if (requiresLogin && !store.state.loggedIn) {
    // Gebruiker is nog niet ingelogd:
    next({
      path: "/login",
      query: {
        next: to.path,
      },
    });
    return;
  }

  if (matched) {
    const page = matched[matched.length - 1];
    store.commit("setCurrentUserRight", page.meta.userRights);
    const found = store.getters.getPageByUserRight(page.meta.userRights);
    if (!defaultPages.includes(found.name) && found.show == false) {
      next({
        path: "/",
      });
      return;
    }
  }
  next();
});

export default router;
