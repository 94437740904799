import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class BillingServices extends Vue {
    currentTab: number | null = 0;

    dialog: boolean = false;

    dialogDelete: boolean = false;
    isLoading: boolean = false;
    selectedTemplate: number | null = null;
    priceIsManually = false;
    editedIndex = -1;

    categories = [
        { text: 'Import', value: 'import' },
        { text: 'Export', value: 'export' },
        { text: 'Single positions', value: 'singlePositions' },
    ];

    subCategories = [
        { text: 'Fixed Values', value: 'fixedValues' },
        { text: 'System Values', value: 'systemValues' },
        { text: 'Flexible Values', value: 'flexibleValues' },
    ];

    editedItem = {
        name: '',
        category: '',
        subCategory: '',
    };
    defaultItem = {
        name: '',
        category: '',
        subCategory: '',
    };

    canEditRatecardName: boolean = false;

    headers = [
        { text: 'Name', value: 'name', align: 'start' },
        { text: 'Category', value: 'category', align: 'start' },
        { text: 'Sub Category', value: 'subCategory', align: 'start' },
        { text: 'Actions', value: 'actions', align: 'end', sortable: false },
    ];

    services = [
        { name: 'Service 1A', category: 'Import', subCategory: 'Fixed Values' },
        {
            name: 'Service 2A',
            category: 'Export',
            subCategory: 'System Values',
        },
        { name: 'Service 3A', category: 'Import', subCategory: 'Fixed Values' },
        { name: 'Service 4A', category: 'Import', subCategory: 'Fixed Values' },
    ];

    @Watch('dialog')
    async onNeedsSavingChanged(val: boolean) {
        val || this.close();
    }

    get formTitle() {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    }

    editItem(item: any) {
        this.editedIndex = this.services.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    }

    deleteItem(item: any) {
        this.editedIndex = this.services.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
    }

    deleteItemConfirm() {
        this.services.splice(this.editedIndex, 1);
        this.closeDelete();
    }

    close() {
        this.dialog = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    save() {
        if (this.editedIndex > -1) {
            Object.assign(this.services[this.editedIndex], this.editedItem);
        } else {
            this.services.push(this.editedItem);
        }
        this.close();
    }
}
