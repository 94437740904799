<template>
    <validation-observer v-slot="{ invalid }">
        <v-form>
            <v-card color="grey darken-3">
                <v-card-title>Consignee address</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <ValidationProvider
                                name="Name"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    hide-details
                                    dense
                                    label="Name"
                                    :error-messages="errors"
                                    outlined
                                    v-model="consolidationStore.consignee.name"
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="6">
                            <ValidationProvider
                                name="Street"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    hide-details
                                    dense
                                    label="Street"
                                    :error-messages="errors"
                                    outlined
                                    v-model="
                                        consolidationStore.consignee.street
                                    "
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                            <ValidationProvider
                                name="Zipcode"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    hide-details
                                    dense
                                    label="Zipcode"
                                    :error-messages="errors"
                                    outlined
                                    v-model="
                                        consolidationStore.consignee.zipCode
                                    "
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                            <ValidationProvider
                                name="City"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    hide-details
                                    dense
                                    label="City"
                                    :error-messages="errors"
                                    outlined
                                    v-model="consolidationStore.consignee.city"
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                            <ValidationProvider
                                name="Country"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-autocomplete
                                    v-model="
                                        consolidationStore.consignee.country
                                    "
                                    :items="dataStore.originCountries"
                                    :error-messages="errors"
                                    item-key="code"
                                    item-text="code"
                                    label="Country"
                                    clearable
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                ></v-autocomplete>
                                <span class="error--text">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                hide-details
                                dense
                                label="EORI-number"
                                outlined
                                v-model="
                                    consolidationStore.consignee.eoriNumber
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                hide-details
                                dense
                                label="VAT number"
                                outlined
                                v-model="consolidationStore.consignee.vatNumber"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <ValidationProvider
                                name="Contact name"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    hide-details
                                    dense
                                    label="Contact name"
                                    :error-messages="errors"
                                    outlined
                                    v-model="
                                        consolidationStore.consignee.contactName
                                    "
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                            <ValidationProvider
                                name="Contact phone"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    hide-details
                                    dense
                                    label="Contact phone"
                                    :error-messages="errors"
                                    outlined
                                    v-model="
                                        consolidationStore.consignee
                                            .contactPhone
                                    "
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                            <ValidationProvider
                                name="Reason for export"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-radio-group
                                    v-model="consolidationStore.reasonForExport"
                                    column
                                >
                                    <template v-slot:label>
                                        <div>
                                            <strong>Reason for export</strong>
                                        </div>
                                    </template>
                                    <v-radio label="Sale" value="01"></v-radio>
                                    <v-radio
                                        label="Return"
                                        value="02"
                                    ></v-radio>
                                    <v-radio label="Gift" value="03"></v-radio>
                                </v-radio-group>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-btn
                            :disabled="invalid"
                            color="primary"
                            @click="$emit('next-step')"
                        >
                            Continue
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script setup lang="ts">
import { useConsolidationStore } from '@/stores/consolidation-store';
import { useDataStore } from '@/stores/data-store';
import { onBeforeMount } from 'vue';
const consolidationStore = useConsolidationStore();
const dataStore = useDataStore();

onBeforeMount(async () => {
    await dataStore.fetchOriginCountries();
});
</script>
