import { Component, Vue } from 'vue-property-decorator';
import ImportRatecard from '@/components/ratecards/ImportRatecard.vue.html';
import ExportRatecard from '@/components/ratecards/ExportRatecard.vue.html';
import SinglePositionsRatecard from '@/components/ratecards/SinglePositionsRatecard.vue.html';

@Component({
    components: {
        ImportRatecard,
        ExportRatecard,
        SinglePositionsRatecard,
    },
})
export default class RateCards extends Vue {
    currentTab: number = 0;
    isLoading: boolean = false;
    selectedEmailTemplate = {};
    selectedPreviewImage: string = '';
    selectedEmailType: number = 0;
    emailTemplates = [];
    emailTypes = [];
    priceHistory = [
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
        {
            changeDate: '01.07.2023',
            validDate: '01.09.2023',
            validTo: '01.11.2023',
        },
    ];

    setLoadingStatus(status: boolean) {
        this.isLoading = status;
    }
}
