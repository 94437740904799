<template>
  <Fragment>
    <v-container fluid>
      <v-row dense class="top-bar" align="center">
        <div class="mrn-search">
          <span>T1 (MRN)</span>
          <span
            ><v-text-field
              outlined
              dense
              hide-details
              readonly
              @click="onMrnFieldClick"
              :value="currentItem?.mrn"
              append-icon="mdi-magnify"
              :disabled="btnActionLoading"
            ></v-text-field
          ></span>
        </div>
        <div
          class="d-flex justify-space-between align-center show-uncompleted-mrn-checkbox"
        >
          <v-checkbox
            v-model="mrnFilters.onlyShowUncompleted"
            label="Only show uncompleted MRN numbers"
            :hide-details="true"
            dense
          ></v-checkbox>
        </div>
        <v-btn
          class="refresh-button"
          color="success"
          small
          fab
          depressed
          :disabled="loadingItems"
          :outlined="!loadingItems"
          @click="getMainOverviewData"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          small
          :loading="resendingAutomaticClearance"
          v-if="totalCount && confirmedMrnStatus?.hasDoneAction"
          @click="resendAutomaticClearance"
          >Resend automatic clearance</v-btn
        >
      </v-row>
      <v-row class="counter-info-container">
        <v-col :cols="12">
          <v-row dense>
            <v-col :cols="5" v-if="totalCount">
              <v-row dense class="top-bar">
                <v-col :cols="6">
                  <strong>{{ currentItem?.mrn }}</strong></v-col
                >
                <v-col :cols="6">
                  <strong>{{ status }} %</strong></v-col
                >
              </v-row>
              <v-row dense class="top-bar">
                <v-col :cols="6">
                  <strong>General information</strong>
                  <div class="counter-item">
                    <span class="counter-item__label">Number of shipments</span>
                    <span class="counter-item__count">{{
                      totalCount?.shipments
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label">Number of pieces</span>
                    <span class="counter-item__count">{{
                      totalCount?.pieces
                    }}</span>
                  </div></v-col
                >
                <v-col :cols="6">
                  <strong>Handling information (DPD only)</strong>
                  <div class="counter-item">
                    <span class="counter-item__label">HUB UK (oubound)</span>
                    <span class="counter-item__count">{{
                      formatData(totalCount?.scannedInHubUK)
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label">HUB DE (inbound)</span>
                    <span class="counter-item__count">{{
                      formatData(totalCount?.scannedInHubDE)
                    }}</span>
                  </div></v-col
                >
              </v-row>
              <v-row dense class="top-bar">
                <v-col :cols="6">
                  <strong>T1 information</strong>
                  <div class="counter-item">
                    <span class="counter-item__label"
                      >Arrival notification sent</span
                    >
                    <span
                      v-if="arrivalNotificationStatus?.hasDoneAction"
                      class="counter-item__count success--text"
                      >Yes</span
                    >
                    <span v-else class="counter-item__count error--text"
                      >No</span
                    >
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label"
                      >Unloading permit received</span
                    >
                    <span
                      v-if="sendeGestellungStatus?.hasDoneAction"
                      class="counter-item__count success--text"
                      >Yes</span
                    >
                    <span v-else class="counter-item__count error--text"
                      >No</span
                    >
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label"
                      >Unloading report sent</span
                    >
                    <span
                      v-if="unloadingReportStatus?.hasDoneAction"
                      class="counter-item__count success--text"
                      >Yes</span
                    >
                    <span v-else class="counter-item__count error--text"
                      >No</span
                    >
                  </div>
                </v-col>
                <v-col :cols="6">
                  <strong>Customs clearance information</strong>
                  <div class="counter-item">
                    <span class="counter-item__label"
                      >Customs clearance sent</span
                    >
                    <span class="counter-item__count">{{
                      formatData(totalCount?.customsClearanceSent)
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label counter-item__label--sub"
                      >of which DAP</span
                    >
                    <span class="counter-item__count">{{
                      formatData(
                        totalCount.customsClearanceSentDAP,
                        totalCount.dap,
                      )
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label counter-item__label--sub"
                      >of which DDP</span
                    >
                    <span class="counter-item__count">{{
                      formatData(
                        totalCount.customsClearanceSentDDP,
                        totalCount.ddp,
                      )
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label"
                      >Status received from customs</span
                    >
                    <span class="counter-item__count">{{
                      formatData(totalCount?.receivedStatus)
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label counter-item__label--sub"
                      >of which pre-cleared</span
                    >
                    <span class="counter-item__count">{{
                      formatData(
                        totalCount?.preCleared,
                        totalCount?.receivedStatus,
                      )
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label counter-item__label--sub"
                      >of which customs inspections</span
                    >
                    <span class="counter-item__count">{{
                      formatData(
                        totalCount?.customsInspections,
                        totalCount?.receivedStatus,
                      )
                    }}</span>
                  </div>
                  <div class="counter-item">
                    <span class="counter-item__label counter-item__label--sub"
                      >of which final cleared shipments</span
                    >
                    <span class="counter-item__count">{{
                      formatData(
                        totalCount?.finalCleared,
                        totalCount?.receivedStatus,
                      )
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="3" v-if="items?.length">
              <div class="actions">
                <v-row dense>
                  <v-btn
                    class="actions__btn"
                    :disabled="
                      !removeMrnLoading &&
                      (btnActionLoading || removedMrnStatus?.hasDoneAction)
                    "
                    @click="onRemoveMrn"
                    :loading="removeMrnLoading"
                    >Remove MRN</v-btn
                  >
                  <label
                    class="action-details"
                    v-if="removedMrnStatus?.hasDoneAction"
                    >{{ removedMrnStatus?.actionDoneBy }},
                    {{ removedMrnStatus?.actionDoneAt }}</label
                  >
                </v-row>
                <v-row dense>
                  <v-btn
                    class="actions__btn disable-events"
                    :ripple="false"
                    :depressed="true"
                    :color="
                      confirmedMrnStatus?.hasDoneAction ? 'success' : 'error'
                    "
                    >{{
                      confirmedMrnStatus?.hasDoneAction
                        ? "Confirmed"
                        : "Not yet confirmed"
                    }}</v-btn
                  >
                  <label
                    class="action-details"
                    v-if="confirmedMrnStatus?.hasDoneAction"
                    >{{ confirmedMrnStatus?.actionDoneBy }},
                    {{ confirmedMrnStatus?.actionDoneAt }}</label
                  >
                </v-row>
                <v-row dense>
                  <v-btn
                    class="actions__btn"
                    :disabled="
                      !sendeGestellungLoading &&
                      (btnActionLoading ||
                        !confirmedMrnStatus?.hasDoneAction ||
                        sendeGestellungStatus?.hasDoneAction)
                    "
                    @click="onSendGestellung"
                    :loading="sendeGestellungLoading"
                    >Sende gestellung</v-btn
                  >
                  <label
                    v-if="sendeGestellungStatus?.hasDoneAction"
                    class="action-details"
                    >{{ sendeGestellungStatus?.actionDoneBy }},
                    {{ sendeGestellungStatus?.actionDoneAt }}</label
                  >
                </v-row>
                <v-row dense>
                  <v-btn
                    class="actions__btn"
                    :disabled="
                      !arrivalNotificationLoading &&
                      (btnActionLoading ||
                        !sendeGestellungStatus?.hasDoneAction ||
                        arrivalNotificationStatus?.hasDoneAction)
                    "
                    :loading="arrivalNotificationLoading"
                    @click="onArrivalNotification"
                    >Arrival notification</v-btn
                  >
                  <label
                    v-if="arrivalNotificationStatus?.hasDoneAction"
                    class="action-details"
                    >{{ arrivalNotificationStatus?.actionDoneBy }},
                    {{ arrivalNotificationStatus?.actionDoneAt }}</label
                  >
                </v-row>
                <v-row dense>
                  <v-btn
                    class="actions__btn"
                    :disabled="
                      !unloadingReportLoading &&
                      (btnActionLoading ||
                        !arrivalNotificationStatus?.hasDoneAction ||
                        unloadingReportStatus?.hasDoneAction)
                    "
                    :loading="unloadingReportLoading"
                    @click="onUnloadingReport"
                    >Unloading report</v-btn
                  >
                  <label
                    v-if="unloadingReportStatus?.hasDoneAction"
                    class="action-details"
                    >{{ unloadingReportStatus?.actionDoneBy }},
                    {{ unloadingReportStatus?.actionDoneAt }}</label
                  >
                </v-row>
              </div>
            </v-col>
            <v-col :cols="4" v-if="items?.length">
              <div class="actions">
                <v-row dense>
                  <v-btn
                    class="actions__btn"
                    :disabled="
                      !confirmGestellungLoading &&
                      (btnActionLoading ||
                        !sendeGestellungStatus?.hasDoneAction ||
                        confirmedGestellungStatus?.hasDoneAction)
                    "
                    :loading="confirmGestellungLoading"
                    @click="onConfirmGestellung"
                    >Bestätige gestellung</v-btn
                  >
                  <label
                    v-if="confirmedGestellungStatus?.hasDoneAction"
                    class="action-details"
                    >{{ confirmedGestellungStatus?.actionDoneBy }},
                    {{ confirmedGestellungStatus?.actionDoneAt }}</label
                  >
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col :cols="12">
          <VirtualDataTable
            :items.sync="items"
            :headers="headers"
            :loading="loadingItems"
            :height="541"
            dense
          >
            <template
              v-for="column in booleanColumns"
              #[`item.${column}`]="{ item }"
            >
              <v-simple-checkbox
                :value="true"
                :color="determineColor(column, item[column])"
                on-icon="mdi-checkbox-intermediate"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
          </VirtualDataTable>
        </v-col>
      </v-row>
    </v-container>
    <ShowMrnDialog
      v-if="showMrnDialog"
      v-model="showMrnDialog"
      :show-confirmed-column="true"
      :only-show-uncompleted="mrnFilters.onlyShowUncompleted"
      :show-status-info="false"
      @getMrn="getMrnData"
    >
    </ShowMrnDialog>
  </Fragment>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import ShowMrnDialog from "@/components/dialogs/ShowMrnDialog.vue";
import {
  CustomsMainOverviewMrnApi,
  MainOverviewActionStatus,
  MrnDto,
} from "@/openapi";
import { DataTableHeader, DataOptions } from "vuetify";
import VirtualDataTable from "@/components/VirtualDataTable.vue";
import { emitErrorWithFallback } from "@/event-bus";

interface TotalCount {
  shipments: number;
  pieces: number;
  scannedInHubUK: number;
  scannedInHubDE: number;
  arrivalNotificationSent: number;
  unloadingPermit: number;
  gestellungGesendet: number;
  unloadingReport: number;
  receivedAtbNumber: number;
  customsClearanceSent: number;
  receivedStatus: number;
  dap: number;
  ddp: number;
  customsClearanceSentDAP: number;
  customsClearanceSentDDP: number;
  preCleared: number;
  customsInspections: number;
  finalCleared: number;
}

interface MrnFilters {
  onlyShowUncompleted: boolean;
}

const api = new CustomsMainOverviewMrnApi(undefined, "");

const headers = ref<DataTableHeader[]>([
  {
    text: "HAWB",
    value: "hawbNumber",
    sortable: true,
    filterable: false,
    width: "180px",
  },
  {
    text: "General customer",
    value: "customer",
    sortable: true,
    filterable: false,
  },
  {
    text: "Pieces",
    value: "pieces",
    sortable: true,
    filterable: false,
  },
  {
    text: "Weight",
    value: "weight",
    sortable: true,
    filterable: false,
  },
  {
    text: "Value",
    value: "value",
    sortable: true,
    filterable: false,
  },
  {
    text: "Currency",
    value: "currency",
    sortable: true,
    filterable: false,
  },
  {
    text: "Incoterm",
    value: "incoterm",
    sortable: true,
    filterable: false,
  },
  {
    text: "Hub UK",
    value: "isScannedInHubUK",
    sortable: false,
    filterable: false,
  },
  {
    text: "Hub DE",
    value: "isScannedInHubDE",
    sortable: false,
    filterable: false,
  },
  {
    text: "Arrival notification",
    value: "hasArrivalNotification",
    sortable: false,
    filterable: false,
  },
  {
    text: "Unloading permit",
    value: "hasUnloadingPermit",
    sortable: false,
    filterable: false,
  },
  {
    text: "Gestellung gesendet",
    value: "gestellungGesendet",
    sortable: false,
    filterable: false,
  },
  {
    text: "Unloading report",
    value: "hasUnloadingReport",
    sortable: false,
    filterable: false,
  },
  {
    text: "ATB Nummer erhalten",
    value: "hasReceivedAtbNumber",
    sortable: false,
    filterable: false,
  },
  {
    text: "Customs clearance sent",
    value: "customsClearanceStatus",
    sortable: false,
    filterable: false,
  },
  {
    text: "Received status",
    value: "receivedStatus",
    sortable: false,
    filterable: false,
  },
  {
    text: "Invoice created",
    value: "hasInvoiceCreated",
    sortable: false,
    filterable: false,
  },
]);

const mrnFilters = ref<MrnFilters>({
  onlyShowUncompleted: true,
});
const showMrnDialog = ref(false);
const currentItem = ref<MrnDto | null>();
const totalCount = ref<TotalCount | null>();

const loadingItems = ref(false);
const items = ref<any[]>([]);
const totalAmountOfItems = ref(0);
const resendingAutomaticClearance = ref(false);

const confirmedMrnStatus = ref<MainOverviewActionStatus>();
const removedMrnStatus = ref<MainOverviewActionStatus>();
const arrivalNotificationStatus = ref<MainOverviewActionStatus>();
const confirmedGestellungStatus = ref<MainOverviewActionStatus>();
const sendeGestellungStatus = ref<MainOverviewActionStatus>();
const unloadingReportStatus = ref<MainOverviewActionStatus>();

const removeMrnLoading = ref(false);
const sendeGestellungLoading = ref(false);
const confirmGestellungLoading = ref(false);
const arrivalNotificationLoading = ref(false);
const unloadingReportLoading = ref(false);

const booleanColumns = computed(() => {
  return [
    "isScannedInHubUK",
    "isScannedInHubDE",
    "hasArrivalNotification",
    "hasUnloadingPermit",
    "gestellungGesendet",
    "hasUnloadingReport",
    "hasReceivedAtbNumber",
    "customsClearanceStatus",
    "receivedStatus",
    "hasInvoiceCreated",
  ];
});

const btnActionLoading = computed(() => {
  return (
    removeMrnLoading.value ||
    sendeGestellungLoading.value ||
    confirmGestellungLoading.value ||
    arrivalNotificationLoading.value ||
    unloadingReportLoading.value
  );
});

const status = computed(() => {
  if (
    (totalCount.value?.finalCleared ?? 0) == 0 ||
    (totalCount.value?.receivedStatus ?? 0) == 0
  ) {
    return 0;
  }
  const devider = 100; // 10 = 1 decimal, 100 = 2 decimals, 1000 = 3 decimals...
  return (
    Math.round(
      (100 / totalCount.value?.receivedStatus!) *
        devider *
        totalCount.value?.finalCleared!,
    ) / devider
  );
});

const onMrnFieldClick = () => {
  showMrnDialog.value = true;
};

const getMrnData = async (eventData: MrnDto) => {
  showMrnDialog.value = false;
  currentItem.value = eventData;
  await getMainOverviewData();
};

const getMainOverviewData = async () => {
  loadingItems.value = true;
  try {
    const response = await api.getMainOverviewData(currentItem.value!.mrn!);
    const data = response.data;
    items.value = data.items ?? [];

    const totalNumberOfShipments = data.numberOfShipments!;

    totalCount.value = {
      shipments: totalNumberOfShipments,
      arrivalNotificationSent: data.numberOfArrivalNotificationSent!,
      customsClearanceSent: data.numberOfCustomsClearanceSent!,
      customsClearanceSentDAP: data.numberOfCustomsClearanceSentDAP!,
      customsClearanceSentDDP: data.numberOfCustomsClearanceSentDDP!,
      dap: data.numberOfTotalDAP!,
      ddp: data.numberOfTotalDDP!,
      gestellungGesendet: data.numberOfGestellungGesendet!,
      pieces: data.numberOfPieces!,
      receivedAtbNumber: data.numberOfReceivedAtbNumber!,
      receivedStatus: data.numberOfReceivedStatus!,
      scannedInHubDE: data.numberOfScannedInHubDE!,
      scannedInHubUK: data.numberOfScannedInHubUK!,
      unloadingPermit: data.numberOfUnloadingPermit!,
      unloadingReport: data.numberOfUnloadingReports!,
      preCleared: data.numberOfPreCleared!,
      customsInspections: data.numberOfCustomsInspections!,
      finalCleared: data.numberOfFinalCleared!,
    };

    confirmedMrnStatus.value = data.confirmedMrn!;
    removedMrnStatus.value = data.removedMrn!;
    arrivalNotificationStatus.value = data.arrivalNotification!;
    confirmedGestellungStatus.value = data.confirmedGestellung!;
    sendeGestellungStatus.value = data.sendeGestellung!;
    unloadingReportStatus.value = data.unloadingReport!;

    totalAmountOfItems.value = response.data.items?.length ?? 0;
  } catch (e: unknown) {}
  loadingItems.value = false;
};

const formatData = (
  currentNumberOfItems: number,
  totalAmountOfItems?: number,
) => {
  totalAmountOfItems ??= totalCount.value?.shipments ?? 0;
  return `${currentNumberOfItems} / ${totalAmountOfItems}`;
};

const onRemoveMrn = async () => {
  removeMrnLoading.value = true;
  try {
    await api.removeMrn({
      mrnNumber: currentItem.value!.mrn,
    });
    currentItem.value = null;
    totalCount.value = null;
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong while removing the MRN");
  }
  removeMrnLoading.value = false;
};

const onSendGestellung = async () => {
  sendeGestellungLoading.value = true;
  try {
    await api.setSendeGestellungStatus({
      mrnNumber: currentItem.value!.mrn,
    });
    await getMainOverviewData();
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      'Something went wrong while during the "sende Gestellung" action',
    );
  }
  sendeGestellungLoading.value = false;
};

const onConfirmGestellung = async () => {
  confirmGestellungLoading.value = true;
  try {
    await api.setBestatigeGestellungStatus({
      mrnNumber: currentItem.value!.mrn,
    });
    await getMainOverviewData();
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      'Something went wrong while during the "bestätige Gestellung" action',
    );
  }
  confirmGestellungLoading.value = false;
};

const onArrivalNotification = async () => {
  arrivalNotificationLoading.value = true;
  try {
    await api.setArrivalNotificationStatus({
      mrnNumber: currentItem.value!.mrn,
    });
    await getMainOverviewData();
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      'Something went wrong while during the "Arrival notification" action',
    );
  }
  arrivalNotificationLoading.value = false;
};

const onUnloadingReport = async () => {
  unloadingReportLoading.value = true;
  try {
    await api.setUnloadingReportStatus({
      mrnNumber: currentItem.value!.mrn,
    });
    await getMainOverviewData();
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      'Something went wrong while during the "Unloading report" action',
    );
  }
  unloadingReportLoading.value = false;
};

const determineColor = (alias: string, value: boolean | number) => {
  if (alias === "customsClearanceStatus") {
    switch (value as number) {
      case 0:
        return "error";
      case 1:
        return "yellow";
      case 2:
        return "blue";
      case 3:
        return "success";
      default:
        return "error";
    }
  }
  return (value as boolean) ? "success" : "error";
};

const resendAutomaticClearance = async () => {
  if (!currentItem.value) {
    return;
  }

  resendingAutomaticClearance.value = true;
  try {
    await api.resendAutomaticClearanceForMRN(currentItem.value.id);
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while resending the automatic clearance",
    );
  } finally {
    resendingAutomaticClearance.value = false;
  }
};
</script>

<style scoped lang="scss">
.mrn-search {
  display: flex;
  align-items: center;
  gap: 15px;
}

.counter-item {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &__label,
  &__title {
    &--list {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    font-weight: bold;
  }

  &__count {
    width: 75px;

    &--list {
      display: flex;
      flex-direction: column;
    }
  }
}

.counter-info-container {
  padding-bottom: 16px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__btn {
    width: 200px;
    align-self: flex-start;
  }
}

.dense-list {
  min-height: initial;
}

.action-details {
  margin-left: 12px;
  align-self: center;
}

.show-uncompleted-mrn-checkbox {
  margin-left: 8px;
}

.refresh-button {
  margin-left: 8px;
}

.top-bar {
  padding: 0 16px;
}
</style>
