<template>
  <v-container class="kpis-container" fluid>
    <div class="v-card__text kpi">
      <v-col cols="12">
        <v-card class="pa-3 kpi-filters mb-1">
          <v-card-title>Filters</v-card-title>
          <v-row>
            <v-col cols="4">
              <DatePicker
                type="date"
                label="From"
                :max="dateNow"
                :min="lastYearDate"
                v-model="filter.from"
              ></DatePicker>
            </v-col>
            <v-col cols="4">
              <DatePicker
                type="date"
                label="Till"
                :max="dateNow"
                :min="kpiFromDate"
                v-model="filter.till"
              ></DatePicker>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="filter.customerId"
                :items="dataStore.generalCustomers"
                item-value="id"
                item-text="shortName"
                label="Customer"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="getKpis" color="primary" dark>filter</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-3 mb-1">
          <v-card-title>KPI's</v-card-title>
          <v-card-text v-if="result">
            <v-simple-table class="kpi-items">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Total number of jobs for Import:</td>
                    <td>{{ result.jobsAmount }}</td>
                  </tr>
                  <tr>
                    <td>Automatically cleared:</td>
                    <td>{{ result.automaticallyCleared }}</td>
                  </tr>
                  <tr>
                    <td>Average number of time in seconds per job:</td>
                    <td>{{ result.averageAmountOfSecondsPerJob }}</td>
                  </tr>
                  <tr>
                    <td>Average number of articles per job:</td>
                    <td>{{ result.averageAmountOfArticlesPerJob }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-text v-else
            >Click <strong>filter</strong> to view results</v-card-text
          >
        </v-card>
      </v-col>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { useDataStore } from "@/stores/data-store";
import DatePicker from "@/components/editors/DatePicker.vue.html";
import {
  KPIsDashboardFilterModel,
  KPIsDashboardModel,
  KPIsDashboardApi,
} from "@/openapi";
import { computed, ref, onMounted } from "vue";

const dataStore = useDataStore();
const api = new KPIsDashboardApi(undefined, "");

const now = new Date();
const from = new Date();
from.setMonth(from.getMonth() - 1);
const filter = ref<KPIsDashboardFilterModel>({
  from: from.toISOString().slice(0, 10),
  till: now.toISOString().slice(0, 10),
  customerId: null,
});
const result = ref<KPIsDashboardModel | null>(null);
const lastYearDate = ref("");
const dateNow = ref(new Date().toISOString().slice(0, 10));
const kpiFromDate = ref("");

const calculateMinDate = computed(() => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date.toISOString().slice(0, 10);
});

lastYearDate.value = calculateMinDate.value;
kpiFromDate.value = lastYearDate.value;

const getKpis = async () => {
  const filterModel = structuredClone(filter.value) as KPIsDashboardFilterModel;
  if (!filterModel.customerId) {
    filterModel.customerId = -1;
  }
  if (filterModel.from) {
    filterModel.from += "T00:00:00";
  }
  if (filterModel.till) {
    filterModel.till += "T00:00:00";
  }
  const response = await api.getKPIs(filter.value);
  if (response.data) {
    result.value = response.data;
  }
};

onMounted(() => {
  dataStore.fetchGeneralCustomers();
});
</script>
<style scoped lang="scss">
.v-data-table.kpi-items {
  table {
    width: auto;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 8px 24px 8px 0;
  font-weight: 600;
  font-size: 1.2rem;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
</style>
