import {
  CountryOfOriginViewModel,
  CurrencyViewModel,
  CustomerViewModel,
  CustomsOfficeViewModel,
  DataApi,
  DestinationCountryViewModel,
  EmployeeViewModel,
  IncotermViewModel,
  Int32StringKeyValueItem,
  ShipmentApi,
  StringStringKeyValueItem,
  TransitDeclarationData,
} from "@/openapi";
import { ActionContext } from "vuex";
import { RootState } from ".";
import { emitError } from "@/event-bus";
export interface DataState {
  consolidationTypes: string[];
  customsReturnLocations: StringStringKeyValueItem[];
  customsHandlingCategories: Int32StringKeyValueItem[];
  countriesOfOrigin: CountryOfOriginViewModel[];
  transitDeclarationDefinitionRoutes: TransitDeclarationData[];
  warehouseLocations: StringStringKeyValueItem[];
  euDestinationCountries: DestinationCountryViewModel[];
  incoterms: IncotermViewModel[];
  generalCustomers: CustomerViewModel[];
  currencies: CurrencyViewModel[];
  origins: Int32StringKeyValueItem[];
  transitTemplates: Int32StringKeyValueItem[];
  employees: EmployeeViewModel[];
  invoiceFooterTexts: Int32StringKeyValueItem[];
  paymentTerms: Int32StringKeyValueItem[];
  customsOffices: CustomsOfficeViewModel[];
}

const shipmentApi = new ShipmentApi(undefined, "");
const dataApi = new DataApi(undefined, "");

export enum DataAction {
  FetchConsolidationTypes = "fetchConsolidationTypes",
  FetchCustomsReturnLocations = "fetchCustomsReturnLocations",
  FetchCustomsHandlingCategories = "fetchCustomsHandlingCategories",
  FetchCountriesOfOrigin = "fetchCountriesOfOrigin",
  FetchTransitDeclarationDefinitionRoutes = "fetchTransitDeclarationDefinitionRoutes",
  FetchWarehouseLocations = "fetchWarehouseLocations",
  FetchEuDestinationCountries = "fetchEuDestinationCountries",
  FetchIncoterms = "fetchIncoterms",
  FetchGeneralCustomers = "fetchGeneralCustomers",
  FetchCurrencies = "fetchCurrencies",
  FetchOrigins = "fetchOrigins",
  FetchTransitTemplates = "fetchTemplates",
  FetchEmployees = "fetchEmployees",
  FetchInvoiceFooterTexts = "fetchInvoiceFooterTexts",
  FetchPaymentTerms = "fetchPaymentTerms",
  FetchCustomsOffices = "fetchCustomsOffices",
}

const getInitialState = () =>
  ({
    consolidationTypes: [],
    customsReturnLocations: [],
    customsHandlingCategories: [],
    countriesOfOrigin: [],
    transitDeclarationDefinitionRoutes: [],
    warehouseLocations: [],
    euDestinationCountries: [],
    incoterms: [],
    generalCustomers: [],
    currencies: [],
    origins: [],
    transitTemplates: [],
    employees: [],
    invoiceFooterTexts: [],
    paymentTerms: [],
    customsOffices: [],
  }) as DataState;

export default {
  state: getInitialState,
  mutations: {
    setConsolidationTypes(state: DataState, consolidationTypes: string[]) {
      state.consolidationTypes = consolidationTypes;
    },
    setCustomsReturnLocations(
      state: DataState,
      customsReturnLocations: StringStringKeyValueItem[],
    ) {
      state.customsReturnLocations = customsReturnLocations;
    },
    setCustomsHandlingCategories(
      state: DataState,
      customsHandlingCategories: Int32StringKeyValueItem[],
    ) {
      state.customsHandlingCategories = customsHandlingCategories;
    },
    setCountriesOfOrigin(
      state: DataState,
      countriesOfOrigin: CountryOfOriginViewModel[],
    ) {
      state.countriesOfOrigin = countriesOfOrigin;
    },
    setTransitDeclarationDefinitionRoutes(
      state: DataState,
      transitRoutes: TransitDeclarationData[],
    ) {
      state.transitDeclarationDefinitionRoutes = transitRoutes;
    },
    setWarehouseLocations(
      state: DataState,
      warehouseLocations: StringStringKeyValueItem[],
    ) {
      state.warehouseLocations = warehouseLocations;
    },
    setEuDestinationCountries(
      state: DataState,
      euDestinationCountries: DestinationCountryViewModel[],
    ) {
      state.euDestinationCountries = euDestinationCountries;
    },
    setIncoterms(state: DataState, incoterms: IncotermViewModel[]) {
      state.incoterms = incoterms;
    },
    setGeneralCustomers(
      state: DataState,
      generalCustomers: CustomerViewModel[],
    ) {
      state.generalCustomers = generalCustomers;
    },
    setCurrencies(state: DataState, currencies: CurrencyViewModel[]) {
      state.currencies = currencies;
    },
    setOrigins(state: DataState, origins: Int32StringKeyValueItem[]) {
      state.origins = origins;
    },
    setTransitTemplates(
      state: DataState,
      transitTemplates: Int32StringKeyValueItem[],
    ) {
      state.transitTemplates = transitTemplates;
    },
    setEmployees(state: DataState, employees: EmployeeViewModel[]) {
      state.employees = employees;
    },
    setInvoiceFooterTexts(
      state: DataState,
      invoiceFooterTexts: Int32StringKeyValueItem[],
    ) {
      state.invoiceFooterTexts = invoiceFooterTexts;
    },
    setPaymentTerms(state: DataState, paymentTerms: Int32StringKeyValueItem[]) {
      state.paymentTerms = paymentTerms;
    },
    setCustomsOffices(
      state: DataState,
      customsOffices: CustomsOfficeViewModel[],
    ) {
      state.customsOffices = customsOffices;
    },
  },
  actions: {
    async fetchConsolidationTypes(
      context: ActionContext<DataState, RootState>,
    ) {
      if (context.state.consolidationTypes?.length) {
        return;
      }

      try {
        const response = await shipmentApi.getConsolidationTypes();
        context.commit("setConsolidationTypes", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the consolidation types",
        );
      }
    },
    async fetchCustomsReturnLocations(
      context: ActionContext<DataState, RootState>,
    ) {
      if (context.state.customsReturnLocations?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsReturnLocations();
        context.commit("setCustomsReturnLocations", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the customs return locations",
        );
      }
    },
    async fetchCustomsHandlingCategories(
      context: ActionContext<DataState, RootState>,
    ) {
      if (context.state.customsHandlingCategories?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsHandlingCategories();
        context.commit("setCustomsHandlingCategories", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the customs handling categories",
        );
      }
    },
    async fetchCountriesOfOrigin(context: ActionContext<DataState, RootState>) {
      if (context.state.countriesOfOrigin?.length) {
        return;
      }

      try {
        const response = await dataApi.getOriginCountries();
        context.commit("setCountriesOfOrigin", response.data);
      } catch {
        emitError("Something went wrong while retrieving the countries");
      }
    },
    async fetchTransitDeclarationDefinitionRoutes(
      context: ActionContext<DataState, RootState>,
    ) {
      if (context.state.transitDeclarationDefinitionRoutes?.length) {
        return;
      }

      try {
        const response = await dataApi.getTransitDeclarationDefinitionRoutes();
        context.commit("setTransitDeclarationDefinitionRoutes", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the transit declaration definition routes.",
        );
      }
    },
    async fetchWarehouseLocations(
      context: ActionContext<DataState, RootState>,
    ) {
      if (context.state.warehouseLocations?.length) {
        return;
      }

      try {
        const response = await dataApi.getWarehouseLocations();
        context.commit("setWarehouseLocations", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the warehouse locations",
        );
      }
    },
    async fetchEuDestinationCountries(
      context: ActionContext<DataState, RootState>,
    ) {
      if (context.state.euDestinationCountries?.length) {
        return;
      }

      try {
        const response = await dataApi.getEuDestinationCountries();
        context.commit("setEuDestinationCountries", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the eu destination countries",
        );
      }
    },
    async fetchIncoterms(context: ActionContext<DataState, RootState>) {
      if (context.state.incoterms?.length) {
        return;
      }

      try {
        const response = await dataApi.getIncoterms();
        context.commit("setIncoterms", response.data);
      } catch {
        emitError("Something went wrong while retrieving the incoterms");
      }
    },
    async fetchGeneralCustomers(context: ActionContext<DataState, RootState>) {
      if (context.state.generalCustomers?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomers(true);
        context.commit("setGeneralCustomers", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the general customers",
        );
      }
    },
    async fetchCurrencies(context: ActionContext<DataState, RootState>) {
      if (context.state.currencies?.length) {
        return;
      }

      try {
        const response = await dataApi.getCurrencies();
        context.commit("setCurrencies", response.data);
      } catch {
        emitError("Something went wrong while retrieving the currencies");
      }
    },
    async fetchOrigins(context: ActionContext<DataState, RootState>) {
      if (context.state.origins?.length) {
        return;
      }

      try {
        const response = await dataApi.getOrigins();
        context.commit("setOrigins", response.data);
      } catch {
        emitError("Something went wrong while retrieving the origins");
      }
    },
    async fetchInvoiceFooterTexts(
      context: ActionContext<DataState, RootState>,
    ) {
      if (context.state.invoiceFooterTexts?.length) {
        return;
      }

      try {
        const response = await dataApi.getInvoiceFooterTexts();
        context.commit("setInvoiceFooterTexts", response.data);
      } catch {
        emitError(
          "Something went wrong while retrieving the invoice footer texts",
        );
      }
    },
    async fetchTransitTemplates(context: ActionContext<DataState, RootState>) {
      if (context.state.transitTemplates?.length) {
        return;
      }

      try {
        const response = await dataApi.getOrigins();
        context.commit("setOrigins", response.data);
      } catch {
        emitError("Something went wrong while retrieving the origins");
      }
    },
    async fetchEmployees(context: ActionContext<DataState, RootState>) {
      if (context.state.employees?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsEmployees();
        context.commit("setEmployees", response.data);
      } catch {
        emitError("Something went wrong while retrieving the employees");
      }
    },
    async fetchPaymentTerms(context: ActionContext<DataState, RootState>) {
      if (context.state.paymentTerms?.length) {
        return;
      }

      try {
        const response = await dataApi.getPaymentTerms();
        context.commit("setPaymentTerms", response.data);
      } catch {
        emitError("Something went wrong while retrieving the payment terms");
      }
    },
    async fetchCustomsOffices(context: ActionContext<DataState, RootState>) {
      if (context.state.customsOffices?.length) {
        return;
      }

      try {
        const response = await dataApi.getCustomsOffices();
        context.commit("setCustomsOffices", response.data);
      } catch {
        emitError("Something went wrong while retrieving the customs offices");
      }
    },
  },
  getters: {
    consolidationTypes: (state: DataState) => state.consolidationTypes,
    warehouseLocations: (state: DataState) => state.warehouseLocations,
    customsHandlingCategories: (state: DataState) =>
      state.customsHandlingCategories,
    countriesOfOrigin: (state: DataState) => state.countriesOfOrigin,
    transitDeclarationDefinitionRoutes: (state: DataState) =>
      state.transitDeclarationDefinitionRoutes,
    customsReturnLocations: (state: DataState) => state.customsReturnLocations,
    euDestinationCountries: (state: DataState) => state.euDestinationCountries,
    incoterms: (state: DataState) => state.incoterms,
    generalCustomers: (state: DataState) => state.generalCustomers,
    currencies: (state: DataState) => state.currencies,
    origins: (state: DataState) => state.origins,
    employees: (state: DataState) => state.employees,
    invoiceFooterTexts: (state: DataState) => state.invoiceFooterTexts,
    paymentTerms: (state: DataState) => state.paymentTerms,
    customsOffices: (state: DataState) => state.customsOffices,
  },
};
