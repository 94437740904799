<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-6">
        <v-card class="pa-6">
          <v-toolbar class="elevation-0 currency-toolbar" dense>
            <v-toolbar-title>Currency Name</v-toolbar-title>  
            <v-spacer></v-spacer>
            <v-text-field
              v-model="currencyInput"
              class="col-2"
              label=""
              single-line
            ></v-text-field>
            <v-btn icon @click="search()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-text-field
              v-model="searchInput"
              class="col-2"
              label=""
              single-line
              icon="mdi-plus"
            ></v-text-field>
            <v-btn icon @click="addItem()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-simple-table class="currency-table">
            <template #default>
              <tbody>
                <tr
                  v-for="(currency, index) in filteredCurrencies"
                  :key="currency.id"
                >
                  <td>
                    <span class="currency-table__currency">
                      {{ currency.name }}
                    </span>
                    <span class="currency-table__buttons">
                      <v-btn icon @click="openDialog(index)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="openDeleteDialog(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn></span
                    >
                  </td>
                </tr>
              </tbody>

              <CurrencyDialog
                :key="timeStampKey"
                :model-open="showDialog"
                :model-value="currentModel"
                :loading="loading"
                @cancel="showDialog = false"
                @save="saveItem"
              />
              <DeleteDialog
                :model-open="showDeleteDialog"
                :name="currentModel.name"
                :deleting="deleting"
                @cancel="showDeleteDialog = false"
                @delete="deleteItem(currentModel.id)"
              />
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { CurrencyApi, DataApi, CurrencyCustomsViewModel } from "@/openapi";
import CurrencyDialog from "@/components/dialogs/CurrencyDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { emitErrorWithFallback } from "@/event-bus";

const defaultModel = {
  id: 0,
  name: null,
  exchangeRate: null,
};

const dataApi = new DataApi(undefined, "");
const currencyApi = new CurrencyApi(undefined, "");

const loading = ref(false);
const deleting = ref(false);
const showDialog = ref(false);
const showDeleteDialog = ref(false);
const currencies = ref<CurrencyCustomsViewModel[]>([]);
const filteredCurrencies = ref<CurrencyCustomsViewModel[]>([]);
const currencyInput = ref<string | null>(null);
const searchInput = ref<string | null>(null);
const currentModel = ref<CurrencyCustomsViewModel>(defaultModel);
const timeStampKey = ref(Date.now());

const openDialog = (index: number) => {
  timeStampKey.value = Date.now();
  if (index > -1) {
    currentModel.value = currencies.value[index];
  } else {
    currentModel.value = { ...defaultModel };
  }
  showDialog.value = true;
};

const openDeleteDialog = (index: number) => {
  currentModel.value = currencies.value[index];
  showDeleteDialog.value = true;
};

const deleteItem = (id: number) => {
  deleting.value = true;
  try {
    currencyApi.deleteCustomsCurrency(id).then(() => {
      deleting.value = false;
      showDeleteDialog.value = false;
      setModels();
    });
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong");
    setModels();
  }
};

const addItem = () => {
  if (!searchInput.value || !searchInput.value?.trim()) {
    return;
  }

  const found = currencies.value.find(
    (item) =>
      item.name?.toLowerCase() == searchInput.value?.toLocaleLowerCase(),
  );
  if (found) {
    emitErrorWithFallback({}, "Currency already exists");
  } else {
    try {
      currencyApi
        .saveCustomsCurrency({
          id: 0,
          name: searchInput.value,
          startDate: null,
          endDate: null,
        })
        .then(() => {
          searchInput.value = "";
          loading.value = false;
          showDialog.value = false;
          setModels();
        });
    } catch (e: unknown) {
      emitErrorWithFallback(e, "Something went wrong");
      setModels();
    }
  }
};

const saveItem = (model: CurrencyCustomsViewModel) => {
  loading.value = true;
  try {
    currencyApi.saveCustomsCurrency(model).then(() => {
      loading.value = false;
      showDialog.value = false;
      setModels();
    });
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong");
    setModels();
  }
};

const search = () => {
  if (!currencyInput.value || !currencyInput.value?.trim()) {
    filteredCurrencies.value = currencies.value;
    return;
  }
  filteredCurrencies.value = currencies.value.filter((p) => {
    return p.name
      ?.toLowerCase()
      .startsWith(currencyInput.value?.toLocaleLowerCase() ?? "");
  });
  return;
};

const setModels = async () => {
  try {
    dataApi.getCurrencies().then((response) => {
      currencies.value = response.data;
      filteredCurrencies.value = currencies.value;
    });
  } catch {
    emitErrorWithFallback("Something went wrong");
  }
};

onMounted(async () => {
  await setModels();
});
</script>
<style lang="scss" scoped>
.currency-toolbar {
  padding-right: 15px;
}
.currency-table {
  td {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.v-ripple__container {
  padding-left: 50px;
}
</style>
