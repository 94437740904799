<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="errors"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 280px)"
      class="elevation-1"
      :loading="loading"
    >
      <template #item.processedDateTime="{ item }">{{
        item.processedDateTime | formatDate("DD-MM-YYYY HH:mm:ss")
      }}</template>
      <template #item.solved="{ item }"
        ><v-icon :color="item.solved ? 'success' : 'error'"
          >{{ item.solved ? "mdi-check" : "mdi-close" }}
        </v-icon></template
      >
      <template #item.actions="{ item }"
        ><v-btn
          color="primary"
          :disabled="item.solved || item.marking"
          :loading="item.marking"
          class="ma-2"
          @click="markAsSolved(item)"
          ><v-icon left>mdi-mail</v-icon>Mark as solved</v-btn
        ></template
      >
    </v-data-table>
  </Fragment>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { DatatransferErrorsApi, ShipmentImporterErrorModel } from "@/openapi";
import { FooterOptions } from "@/types/types";

interface ErrorItem extends ShipmentImporterErrorModel {
  marking: boolean;
}

const api = new DatatransferErrorsApi(undefined, "");
const errors = ref<ErrorItem[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const headers = ref<DataTableHeader[]>([
  {
    text: "Path",
    value: "filePath",
    align: "start",
    width: "200px",
  },
  {
    text: "Customer",
    value: "customer",
    align: "start",
    width: 200,
  },
  {
    text: "ProcessedDateTime",
    value: "processedDateTime",
    align: "start",
    width: 175,
  },
  {
    text: "Error message",
    value: "errorMessage",
    align: "start",
  },
  {
    text: "Solved",
    value: "solved",
    align: "start",
    width: 50,
  },
  {
    text: "",
    value: "actions",
    align: "start",
    width: 250,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const getErrors = async (page?: number) => {
  page ??= options.value.page;
  loading.value = true;
  const response = await api.getShipmentImporterErrors(
    page,
    options.value.itemsPerPage,
  );
  errors.value =
    response.data.items?.map((item) => ({ ...item, marking: false })) ?? [];
  totalAmountOfItems.value = response.data.totalPages;
  loading.value = false;
};

const markAsSolved = async (error: ErrorItem) => {
  error.marking = true;
  await api.markShipmentImporterErrorAsSolved(error.id);
  const index = errors.value.findIndex((item) => item.id == error.id);
  getErrors();
};

watch(
  () => options.value,
  async (newValue, oldValue) => {
    await getErrors();
  },
  { deep: true },
);
</script>
<style lang="scss"></style>
