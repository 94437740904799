<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-tabs v-model="currentTab">
          <v-tab>Active</v-tab>
          <v-tab>History</v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item
            ><PreCheckShipmentsOverview></PreCheckShipmentsOverview
          ></v-tab-item>
          <v-tab-item
            ><PreCheckShipmentsOverview
              :historical="true"
            ></PreCheckShipmentsOverview
          ></v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PreCheckShipmentsOverview from "@/components/PreCheckShipmentsOverview.vue";
const currentTab = ref(0);
</script>
