import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { ToolbarItem } from '../models/ToolbarItem';
import {
    CustomsOfficeExportFlowApi,
    CustomsOfficeExportFlowViewModel,
} from '@/openapi';

@Component({})
export default class CustomsOfficeExportFlow extends Vue {
    api = new CustomsOfficeExportFlowApi(undefined, '');

    headers = [
        { text: 'Customer', value: 'customerName' },
        { text: 'Custom Office', value: 'customsOfficeName' },
    ];

    saveSuccessful = false;
    expanded = [];

    observer!: InstanceType<typeof ValidationObserver>;

    isLoading = false;

    currentCustomsOfficeExportFlowItem: CustomsOfficeExportFlowViewModel[] = [];
    customsOfficeExportFlowItems: CustomsOfficeExportFlowViewModel[] = [];

    flowSteps = [
        // CHECK MODUS per Shipment
        ['Start', 'Start', 'Start', 'yellow darken-3', 'Start'],
        [
            'Shipment data received',
            'Shipment data received',
            'Shipment data received',
            'yellow darken-3',
            'Shipment data received',
        ],
        [
            'Check shipment',
            'Check shipment',
            'Check shipment',
            'yellow darken-3',
            'Check shipment',
        ],

        // EXPORT MODUS per WAB
        [
            '< 1.000',
            '1.000 - 3.000',
            '> 3.000',
            'light-blue darken-1',
            'Value breaks',
        ],
        [
            'Confirm loading',
            'Confirm loading',
            'Confirm loading',
            'light-blue darken-1',
            'Confirm loading',
        ],
        [
            'Excel sheet created',
            'Declaration by Dakosy',
            'Movement Reference Number (MRN) entered',
            'light-blue darken-1',
            'Files/MRN created',
        ],
        [
            'Pending customs release',
            'Pending customs release',
            'Movement Reference Number (MRN) entered',
            'light-blue darken-1',
            'Pending customs release',
        ],
        [
            'Confirm reply from customs',
            'Confirm reply from Dakosy',
            'Movement Reference Number (MRN) entered',
            'light-blue darken-1',
            'Customs EXIT released',
        ],

        // TRANSIT MODUS per WAB
        [
            'Confirm WAB ready to depart',
            'Confirm WAB ready to depart',
            'Confirm WAB ready to depart',
            'purple',
            'Confirm WAB ready to depart',
        ],
        ['T1 creation', 'T1 creation', 'T1 creation', 'purple', 'T1 creation'],
        ['T2 released', 'T2 released', 'T2 released', 'purple', 'T2 released'],
        [
            'CONSO file created',
            'CONSO file created',
            'CONSO file created',
            'purple',
            'CONSO file created',
        ],
        [
            'T2 discharged',
            'T2 discharged',
            'T2 discharged',
            'purple',
            'T2 discharged',
        ],

        // FULLFILLED
        [
            'Fullfilled',
            'Fullfilled',
            'Fullfilled',
            'light-green darken-2',
            'Fullfilled',
        ],
    ];

    dataReceivedStep = 1;
    checkShipmentStep = 2;

    valueBreaksStep = 3;
    confirmLoadingStep = 4;
    confirmDocumentOrMRN = 5;
    pendingCustomsStep = 6;
    confirmManifestStep = 7;

    confirmWABStep = 8;
    t2DischargedStep = 13;

    fullfilledStep = 14;

    flowItemsFlow0 = [
        { flowText: 'Create Excel sheet', flowValue: '0' },
        { flowText: 'Declaration by Dakosy', flowValue: '1' },
        { flowText: 'Declaration by customer', flowValue: '2' },
    ];
    selectedFlowValueFlow0 = '';

    flowItemsFlow1 = [
        { flowText: 'Declaration by Dakosy', flowValue: '1' },
        { flowText: 'Declaration by customer', flowValue: '2' },
    ];
    selectedFlowValueFlow1 = '';

    @Watch('expanded')
    onExpandedChanged(
        newVal: CustomsOfficeExportFlowViewModel[],
        oldVal: CustomsOfficeExportFlowViewModel[]
    ) {
        var customsOfficeExportFlowId = newVal[0].id;
        this.isLoading = true;
        this.api
            .getCustomsOfficeExportFlowItemById(customsOfficeExportFlowId)
            .then((response) => {
                this.currentCustomsOfficeExportFlowItem = response.data ?? [];
                this.selectedFlowValueFlow0 =
                    this.currentCustomsOfficeExportFlowItem[0].flowPathIndex.toString();
                this.selectedFlowValueFlow1 =
                    this.currentCustomsOfficeExportFlowItem[1].flowPathIndex.toString();
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
            });
    }

    changeFlow0(flowIndex: number) {
        this.currentCustomsOfficeExportFlowItem[0].flowPathIndex = flowIndex;
    }

    changeFlow1(flowIndex: number) {
        this.currentCustomsOfficeExportFlowItem[1].flowPathIndex = flowIndex;
    }

    created() {
        var toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refresh(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview',
            },
        ];
        this.$emit(
            'PageInfoReceived',
            'Customs Office Export Flows',
            toolbarButtons
        );

        this.loadData();
    }

    mounted() {
        this.observer = this.$refs.observer as InstanceType<
            typeof ValidationObserver
        >;
    }

    refresh() {
        this.loadData();
    }

    loadData() {
        this.isLoading = true;
        this.api
            .getCustomsOfficeExportFlowItems()
            .then((response) => {
                this.customsOfficeExportFlowItems = response.data;
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
            });
    }

    save(saveSuccessfulCallback: Function) {
        for (let flow = 0; flow < 2; flow++) {
            this.api
                .saveCustomsOfficeExportFlowItem(
                    this.currentCustomsOfficeExportFlowItem![flow]
                )
                .then((result) => {
                    this.currentCustomsOfficeExportFlowItem[flow] = result.data;
                    let item = this.customsOfficeExportFlowItems!.find(
                        (x) =>
                            x.id ===
                            this.currentCustomsOfficeExportFlowItem![flow].id
                    );
                    item!.id = this.currentCustomsOfficeExportFlowItem[flow].id;
                    item!.customerId =
                        this.currentCustomsOfficeExportFlowItem[
                            flow
                        ].customerId;
                    item!.customsOfficeId =
                        this.currentCustomsOfficeExportFlowItem[
                            flow
                        ].customsOfficeId;
                    item!.flowIndex =
                        this.currentCustomsOfficeExportFlowItem[flow].flowIndex;
                    item!.flowPathIndex =
                        this.currentCustomsOfficeExportFlowItem[
                            flow
                        ].flowPathIndex;

                    this.saveSuccessful = true;
                    if (saveSuccessfulCallback) {
                        saveSuccessfulCallback();
                    }
                })
                .catch((error) => {});
        }
    }

    range(start: number, end: number) {
        return Array(end - start + 1)
            .fill(null)
            .map((_, idx) => start + idx);
    }
}
