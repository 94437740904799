import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class RatecardValues extends Vue {
    @Prop({ default: '' })
    name!: string;

    priceIsManually = false;

    canEditRatecardName: boolean = false;

    subCategories = ['A1 service', 'A2 service', 'A3 service'];
    selectedSubCategories = [];

    showDialogDeleteRatecard: boolean = false;

    deleteRatecard() {
        // SOVC-669 - WIP
    }

    openDialogDeleteRatecard() {
        this.showDialogDeleteRatecard = true;
    }

    editRatecardName() {
        this.canEditRatecardName = true;
    }

    saveRatecard() {
        // SOVC-669 - WIP
    }
}
