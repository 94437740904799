import { emitError } from "@/event-bus";
import { CustomsHandlingConsolidationViewModel, ShipmentApi } from "@/openapi";
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import CreateConsolidationDialog from "../dialogs/CreateConsolidationDialog.vue.html";
import { downloadFile } from "@/helpers/downloadHelper";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue.html";
import { useDataStore } from "@/stores/data-store";

interface Filters {
  customsReturnLocation: string | null;
  category: number | null;
}

const DapHoldNoInvoiceCreated = 3;
const DapHoldNotCustomsCleared = 4;

@Component({
  components: {
    CreateConsolidationDialog,
    ConfirmDialog,
  },
})
export default class ShipmentsOnStock extends Vue {
  api: ShipmentApi = new ShipmentApi(undefined, "");
  dataStore = useDataStore();
  headers: DataTableHeader[] = [
    {
      text: "HAWB",
      align: "start",
      value: "hawb",
      groupable: false,
      sortable: false,
    },
    { text: "Pieces", value: "pieces", groupable: false, sortable: false },
    { text: "Weight", value: "weight", groupable: false, sortable: false },
    {
      text: "Multi piece complete",
      value: "multiPieceComplete",
      groupable: false,
      sortable: false,
    },
    { text: "Value", value: "value", groupable: false, sortable: false },
    {
      text: "Value currency",
      value: "valueCurrency",
      groupable: false,
      sortable: false,
    },
    {
      text: "Warehouse location",
      value: "warehouseLocation",
      groupable: false,
      sortable: false,
    },
    {
      text: "Incoterm",
      value: "incoterm",
      groupable: false,
      sortable: false,
    },
    {
      text: "Category",
      value: "category",
      groupable: false,
      sortable: false,
    },
    {
      text: "Inbound scan date",
      value: "inboundScanDate",
      groupable: false,
      sortable: false,
    },
    { text: "", value: "actions", sortable: false },
  ];

  options: DataOptions = {
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100, -1],
    disablePagination: false,
  };

  totalAmountOfItems: number = 0;

  items: CustomsHandlingConsolidationViewModel[] = [];

  selectedItems: CustomsHandlingConsolidationViewModel[] = [];
  isLoading = false;
  loadingHideShipments = false;

  showCreateConsolidationDialog = false;
  showHideConfirmDialog = false;
  filters: Filters = {
    customsReturnLocation: null,
    category: null,
  };

  async created() {
    await Promise.all([
      this.dataStore.fetchWarehouseLocations(),
      this.dataStore.fetchCustomsHandlingCategories(),
    ]);
  }

  timeoutDelay = 0;
  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.getShipments(this.options.page);
    }, 250);
  }

  openCreateConsolidationModal() {
    this.showCreateConsolidationDialog = true;
  }

  cancelCreateConsolidation() {
    this.showCreateConsolidationDialog = false;
  }

  async getShipments(page: number) {
    this.isLoading = true;
    try {
      const response = await this.api.getShipmentsOnStock(
        this.filters.customsReturnLocation ?? undefined,
        this.filters.category ?? undefined,
        page,
        this.options.itemsPerPage,
      );
      this.items = response.data.items ?? [];
      this.options.page = response.data.page ?? 0;
      this.options.itemsPerPage = response.data.itemsPerPage ?? 0;
      this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
      this.selectedItems = [];
    } catch {
      emitError("Someting went wrong while retrieving the items.");
    }
    this.isLoading = false;
  }

  async reloadShipments() {
    this.options.page == 1
      ? await this.getShipments(1)
      : (this.options.page = 1);
  }

  async hideShipment() {
    this.loadingHideShipments = true;
    try {
      await this.api.hideShipment(this.selectedShipmentIds);
      this.selectedItems = [];
      await this.reloadShipments();
    } catch {
      emitError("Something went wrong while hiding the items.");
    }
    this.loadingHideShipments = false;
    this.showHideConfirmDialog = false;
  }

  async exportShipments() {
    if (!this.items.length) {
      return;
    }
    try {
      const response = await this.api.exportShipment(
        {
          hasConsolidation: false,
          shipmentIds: this.items.map((item) => item.id!),
        },
        {
          responseType: "blob",
        },
      );
      downloadFile(response.data, "OnStock-" + Date.now() + ".xlsx");
    } catch {
      emitError("Something went wrong during the export");
    }
  }

  async onCreatedConsolidation() {
    this.selectedItems = [];
    await this.reloadShipments();
  }

  async onFilterSelected() {
    await this.reloadShipments();
  }

  getCategoryName(category: number) {
    return (
      this.dataStore.customsHandlingCategories.find((c) => c.key === category)
        ?.value ?? ""
    );
  }

  get hasItems() {
    return !!this.items?.length;
  }

  get hasSelectedItems() {
    return !!this.selectedItems?.length;
  }

  get selectedShipmentIds() {
    return this.selectedItems?.map((c) => c.id!) ?? [];
  }

  get hasOnlyNoInvoiceCreatedOrNotCustomsClearedCategories() {
    if (!this.selectedItems.length) {
      return false;
    }
    return this.selectedItems.every(
      (c) =>
        c.category == DapHoldNoInvoiceCreated ||
        c.category == DapHoldNotCustomsCleared,
    );
  }

  get notAllowedShipmentsForConsolidationCreation() {
    if (this.hasOnlyNoInvoiceCreatedOrNotCustomsClearedCategories) {
      return [];
    }
    return this.selectedItems
      .filter(
        (c) =>
          c.category == DapHoldNoInvoiceCreated ||
          c.category == DapHoldNotCustomsCleared,
      )
      .map((c) => c.hawb);
  }

  get canCreateConsolidation() {
    if (this.hasOnlyNoInvoiceCreatedOrNotCustomsClearedCategories) {
      return true;
    }
    return (
      this.hasSelectedItems &&
      !this.selectedItems.some(
        (c) =>
          c.category === DapHoldNoInvoiceCreated ||
          c.category === DapHoldNotCustomsCleared,
      )
    );
  }
}
