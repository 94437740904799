import { ValidationObserver } from 'vee-validate';
import { Ref, watch } from 'vue';

export function useFormReset<T>(
    valueToWatch: Ref<boolean>,
    formData: Ref<T>,
    defaultFormValues: T,
    observer?: Ref<InstanceType<typeof ValidationObserver> | undefined>
) {
    const resetForm = () => {
        if (!valueToWatch.value) {
            formData.value = { ...defaultFormValues };
            observer?.value?.reset();
        }
    };

    watch(() => valueToWatch.value, resetForm);
}
