<template>
  <div v-if="show">
    <v-list-group
      v-if="!props.item.isPage"
      v-bind="{ 'sub-group': props.subGroup }"
    >
      <template #activator>
        <v-list-item-title>{{ props.item.title }}</v-list-item-title>
      </template>
      <menu-item
        v-for="child in children"
        :key="child.name"
        :item="child"
        :sub-group="true"
      >
      </menu-item>
    </v-list-group>
    <v-divider v-if="!props.item.isPage && !props.subGroup"></v-divider>
    <v-list-item v-if="props.item.isPage" link :to="props.item.path">
      <v-list-item-title class="menu-indent">{{
        props.item.title
      }}</v-list-item-title>
    </v-list-item>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { type MenuItem } from "@/stores/route-store";

interface MenuItemProps {
  item: MenuItem;
  subGroup: boolean;
}
const props = defineProps<MenuItemProps>();

const children = computed(() => {
  return !props.item.isPage &&
    !!props.item.children?.filter((item) => item.show && item.showInMenu)
    ? props.item.children?.filter((item) => item.show && item.showInMenu)
    : [];
});
const show = computed(() => {
  return props.item.show && props.item.showInMenu;
});
</script>

<style scoped lang="scss"></style>
