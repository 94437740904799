<template>
  <ValidationObserver v-slot="{ invalid, dirty }" slim>
    <div class="form">
      <div class="form-group">
        <span class="form-group__header">Personal</span>
        <div class="form-group__fields">
          <ValidationProvider
            v-slot="{ errors }"
            name="First name"
            rules="max:50"
          >
            <v-text-field
              v-model="form.firstName"
              label="First name"
              placeholder="First name"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="50"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Add. names"
            rules="max:100"
          >
            <v-text-field
              v-model="form.additionalNames"
              label="Add. names"
              placeholder="Add. names"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="100"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Last name"
            rules="max:100"
          >
            <v-text-field
              v-model="form.lastName"
              label="Last name"
              placeholder="Last name"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="100"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Birthdate">
            <DatePicker
              v-model="form.birthDate"
              label="Birthdate"
              placeholder="Birthdate"
              :outlined="true"
              :dense="true"
              :disabled="loading"
              :loading="false"
              :error-messages="errors"
            ></DatePicker>
          </ValidationProvider>
        </div>
      </div>

      <div class="form-group">
        <span class="form-group__header"> Address </span>
        <div class="form-group__fields">
          <ValidationProvider
            v-slot="{ errors }"
            name="Add. address"
            rules="max:50"
          >
            <v-text-field
              v-model="form.additionalAddress"
              label="Add. address"
              placeholder="Add. address"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="50"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Street" rules="max:50">
            <v-text-field
              v-model="form.street"
              label="Street"
              placeholder="Street"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="50"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Zip" rules="max:20">
            <v-text-field
              v-model="form.zipCode"
              label="Zip"
              placeholder="Zip"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="20"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="City" rules="max:50">
            <v-text-field
              v-model="form.city"
              label="City"
              placeholder="City"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="50"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Country">
            <v-autocomplete
              v-model="form.country"
              label="Country"
              :items="dataStore.originCountries"
              item-value="name"
              item-text="name"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :error-messages="errors"
            ></v-autocomplete>
          </ValidationProvider>
        </div>
      </div>

      <div class="form-group">
        <span class="form-group__header"> Contact </span>
        <div class="form-group__fields">
          <ValidationProvider
            v-slot="{ errors }"
            name="Com. phone"
            rules="max:20"
          >
            <v-text-field
              v-model="form.companyPhone"
              label="Com. phone"
              placeholder="Com. phone"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="20"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Com. mobile"
            rules="max:20"
          >
            <v-text-field
              v-model="form.companyMobile"
              label="Com. mobile"
              placeholder="Com. mobile"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="20"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Priv. phone"
            rules="max:20"
          >
            <v-text-field
              v-model="form.phone"
              label="Priv. phone"
              placeholder="Priv. phone"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="20"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Emergency phone"
            rules="max:20"
          >
            <v-text-field
              v-model="form.emergencyPhone"
              label="Emergency phone"
              placeholder="Emergency phone"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="20"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Email"
            rules="max:50|email"
          >
            <v-text-field
              v-model="form.email"
              label="Email"
              placeholder="Email"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="50"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Com. email"
            rules="max:50|email"
          >
            <v-text-field
              v-model="form.companyEmail"
              label="Com. email"
              placeholder="Com. email"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="50"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </div>
      </div>

      <div class="form-group">
        <span class="form-group__header">Login</span>
        <div class="form-group__fields">
          <ValidationProvider
            v-slot="{ errors }"
            name="Login"
            rules="required|max:20"
          >
            <v-text-field
              v-model="form.login"
              label="Login*"
              placeholder="Login"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="20"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Password"
            :rules="passwordRules"
          >
            <v-text-field
              v-model="form.password"
              type="password"
              :label="passwordLabel"
              placeholder="Password"
              outlined
              dense
              :disabled="loading"
              :loading="false"
              :counter="20"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="AccessLevel"
            rules="required"
          >
            <v-text-field
              v-if="store.state.accessLevel == 10 && !form.isOwn"
              v-model="form.accessLevel"
              label="Access Level"
              placeholder="AccessLevel"
              outlined
              dense
              type="number"
              min="1"
              :max="store.state.accessLevel"
              :disabled="loading"
              :loading="false"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </div>
      </div>

      <div class="form-group">
        <span class="form-group__header">Other settings</span>
        <div class="form-group__fields">
          <v-checkbox
            v-model="form.showInEmployeeLists"
            label="Show in employee lists"
            outlined
            dense
            hide-details
          ></v-checkbox>
        </div>
      </div>

      <v-btn
        color="success"
        class="save-btn mt-4"
        :disabled="(!saving && loading) || invalid"
        :loading="saving"
        @click="saveEmployee"
        ><v-icon left small>mdi-content-save</v-icon>{{ buttonText }}</v-btn
      >
    </div>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { emitError, emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { EmployeesApi } from "@/openapi";
import { computed, onBeforeMount, ref, watch } from "vue";
import { useDataStore } from "@/stores/data-store";
import store from "@/store";
import router from "@/router";

interface EmployeeDetailFormData {
  id: number;
  firstName: string;
  additionalNames: string;
  lastName: string;
  additionalAddress: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  companyPhone: string;
  companyMobile: string;
  phone: string;
  emergencyPhone: string;
  email: string;
  companyEmail: string;
  birthDate: string;
  login: string;
  password: string;
  accessLevel: number;
  isOwn: boolean;
  showInEmployeeLists: boolean;
}

interface EmployeeDetailDataProps {
  employeeId?: number;
}

const employeesApi = new EmployeesApi(undefined, "");
const dataStore = useDataStore();
const props = defineProps<EmployeeDetailDataProps>();

const defaultFormValues: EmployeeDetailFormData = {
  id: 0,
  firstName: "",
  additionalNames: "",
  lastName: "",
  additionalAddress: "",
  street: "",
  zipCode: "",
  city: "",
  country: "",
  companyPhone: "",
  companyMobile: "",
  phone: "",
  emergencyPhone: "",
  email: "",
  companyEmail: "",
  birthDate: "",
  login: "",
  password: "",
  accessLevel: 0,
  isOwn: false,
  showInEmployeeLists: false,
};

const form = ref<EmployeeDetailFormData>({ ...defaultFormValues });
const loading = ref(false);
const saving = ref(false);

const getEmployee = async () => {
  loading.value = true;
  try {
    const response = await employeesApi.getEmployee(props.employeeId!);
    const data = response.data;
    form.value.id = data.id!;
    form.value.firstName = data.firstName ?? "";
    form.value.additionalNames = data.additionalNames ?? "";
    form.value.lastName = data.lastName ?? "";
    form.value.additionalAddress = data.additionalAddress ?? "";
    form.value.street = data.street ?? "";
    form.value.zipCode = data.zipCode ?? "";
    form.value.city = data.city ?? "";
    form.value.country = data.country ?? "";
    form.value.companyPhone = data.companyPhone ?? "";
    form.value.companyMobile = data.companyMobile ?? "";
    form.value.phone = data.phone ?? "";
    form.value.emergencyPhone = data.emergencyPhone!;
    form.value.email = data.email ?? "";
    form.value.companyEmail = data.companyEmail ?? "";
    form.value.birthDate = data.birthdate ?? "";
    form.value.login = data.loginName ?? "";
    form.value.password = "";
    form.value.accessLevel = data.accessLevel ?? 0;
    form.value.isOwn = data.isOwn ?? false;
    form.value.showInEmployeeLists = data.showInEmployeeLists;
  } catch (e: unknown) {
    emitError("Something went wrong while retrieving the employee data");
  }
  loading.value = false;
};

const saveEmployee = async () => {
  saving.value = true;
  loading.value = true;

  if (isNew.value) {
    await createEmployee();
  } else {
    await updateEmployee();
  }

  saving.value = false;
  loading.value = false;
};

const createEmployee = async () => {
  try {
    const response = await employeesApi.postEmployee({
      firstName: form.value.firstName,
      additionalNames: form.value.additionalNames,
      lastName: form.value.lastName,
      additionalAddress: form.value.additionalAddress,
      street: form.value.street,
      zipCode: form.value.zipCode,
      city: form.value.city,
      country: form.value.country,
      companyPhone: form.value.companyPhone,
      companyMobile: form.value.companyMobile,
      privatePhone: form.value.phone,
      emergencyPhone: form.value.emergencyPhone,
      privateEmail: form.value.email,
      companyEmail: form.value.companyEmail,
      birthdate: form.value.birthDate,
      loginName: form.value.login,
      password: form.value.password,
      accessLevel: form.value.accessLevel,
      showInEmployeeLists: form.value.showInEmployeeLists,
    });
    emitSuccess("Created successfully");
    router.replace({
      name: router.currentRoute.name!,
      params: {
        employeeId: response.data.id.toString(),
      },
    });
    return Promise.resolve();
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while creating");
  }
};

const updateEmployee = async () => {
  try {
    await employeesApi.putEmployee({
      id: form.value.id,
      firstName: form.value.firstName,
      additionalNames: form.value.additionalNames,
      lastName: form.value.lastName,
      additionalAddress: form.value.additionalAddress,
      street: form.value.street,
      zipCode: form.value.zipCode,
      city: form.value.city,
      country: form.value.country,
      companyPhone: form.value.companyPhone,
      companyMobile: form.value.companyMobile,
      privatePhone: form.value.phone,
      emergencyPhone: form.value.emergencyPhone,
      privateEmail: form.value.email,
      companyEmail: form.value.companyEmail,
      birthdate: form.value.birthDate,
      loginName: form.value.login,
      password: form.value.password,
      accessLevel: form.value.accessLevel,
      showInEmployeeLists: form.value.showInEmployeeLists,
    });
    emitSuccess("Updated successfully");
    return Promise.resolve();
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while updating");
  }
};

watch(
  () => props.employeeId,
  (newValue: any, oldValue: any) => {
    if (newValue) {
      getEmployee();
    }
  },
  {
    immediate: true,
  },
);

const isNew = computed(() => {
  return !props.employeeId;
});

const buttonText = computed(() => {
  return isNew.value ? "Create" : "Edit";
});

const passwordRules = computed(() => {
  return isNew.value ? "required|max:20" : "max:20";
});

const passwordLabel = computed(() => {
  return isNew.value ? "Password*" : "Password";
});

onBeforeMount(async () => {
  await dataStore.fetchOriginCountries();
});
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  &__header {
    display: block;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.form-group__fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
}

.save-btn {
  align-self: flex-end;
}
</style>
