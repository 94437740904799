<template>
  <div>
    <v-card-title>{{ colorPickerTitle }}</v-card-title>
    <v-text-field v-model="textFieldModel" class="ma-0 pa-0" solo>
      <template v-slot:append>
        <v-menu
          v-model="menu"
          top
          nudge-bottom="1050"
          nudge-left="16"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <div class="swatch" v-on="on" />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker v-model="textFieldModel" />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const menu = ref<boolean>(false);

const props = withDefaults(
  defineProps<{
    colorPickerTitle?: string;
    textFieldModel: string;
  }>(),
  {
    textFieldModel: "000000",
  },
);
</script>
<style lang="scss">
.swatch {
  background-color: v-bind(textFieldModel);
  cursor: pointer;
  height: 30px;
  width: 30px;
}
</style>
