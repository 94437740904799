<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="errors"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 280px)"
      class="elevation-1"
      :loading="loading"
    >
      <template #item.createdDateTime="{ item }">{{
        item.createdDateTime | formatDate("DD-MM-YYYY HH:mm:ss")
      }}</template>
      <template #item.isClearanceConfirmation="{ item }"
        ><v-icon :color="item.isClearanceConfirmation ? 'success' : 'error'"
          >{{ item.isClearanceConfirmation ? "mdi-check" : "mdi-close" }}
        </v-icon></template
      >
      <template #item.isImpostExport="{ item }"
        ><v-icon :color="item.isImpostExport ? 'success' : 'error'"
          >{{ item.isImpostExport ? "mdi-check" : "mdi-close" }}
        </v-icon></template
      >
      <template #item.actions="{ item }"
        ><v-btn
          color="primary"
          :disabled="item.resending"
          :loading="item.resending"
          class="ma-2"
          @click="resend(item)"
          ><v-icon left>mdi-mail</v-icon>Resend</v-btn
        ></template
      >
    </v-data-table>
  </Fragment>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { DatatransferErrorsApi, DakosyShipmentErrorModel } from "@/openapi";
import { FooterOptions } from "@/types/types";

interface ErrorItem extends DakosyShipmentErrorModel {
  resending: boolean;
}

const api = new DatatransferErrorsApi(undefined, "");
const errors = ref<ErrorItem[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const headers = ref<DataTableHeader[]>([
  {
    text: "HAWB",
    value: "hawbNumber",
    align: "start",
    width: 200,
  },
  {
    text: "Created",
    value: "createdDateTime",
    align: "start",
    width: 200,
  },
  {
    text: "Created by employee",
    value: "employee",
    align: "start",
  },
  {
    text: "Error message",
    value: "errorMessage",
    align: "start",
  },
  {
    text: "Is confirmation",
    value: "isClearanceConfirmation",
    align: "start",
    width: 120,
  },
  {
    text: "Is IMPOST",
    value: "isImpostExport",
    align: "start",
    width: 100,
  },
  {
    text: "Type",
    value: "typeName",
    align: "start",
    width: 200,
  },
  {
    text: "",
    value: "actions",
    align: "start",
    width: 150,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const getErrors = async (page?: number) => {
  page ??= options.value.page;
  loading.value = true;
  const response = await api.getDakosyShipmentErrors(
    page,
    options.value.itemsPerPage,
  );
  errors.value =
    response.data.items?.map((item) => ({ ...item, resending: false })) ?? [];
  totalAmountOfItems.value = response.data.totalPages;
  loading.value = false;
};

const resend = async (error: ErrorItem) => {
  error.resending = true;
  await api.resendDakosyShipmentError(error.id);
  const index = errors.value.findIndex((item) => item.id == error.id);
  getErrors();
};

watch(
  () => options.value,
  async (newValue, oldValue) => {
    await getErrors();
  },
  { deep: true },
);
</script>
<style lang="scss"></style>
